<template>
  <div>
    <v-alert class="mt-4" border="left" :value="alert.isVisible" :type="alert.type" text :color="alert.color">{{ alert.message }} </v-alert>

    <v-form ref="form" @submit.prevent="onSubmit">
      <v-row>
        <v-col :cols="12">
          <v-text-field
            :label="$t('NewPassword')"
            v-model="form.password"
            type="password"
            :error-messages="passwordErrors"
            @blur="$v.form.password.$touch()"
            required
            autocomplete="new-password"
          >
          </v-text-field>
        </v-col>

        <v-col :cols="12">
          <v-text-field
            :label="$t('RepeatPassword')"
            v-model="form.password2"
            type="password"
            :error-messages="password2Errors"
            @blur="$v.form.password2.$touch()"
            required
            autocomplete="new-password"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12" class="text-right">
          <v-btn color="primary" type="submit" depressed :loading="isLoading">{{ $t('ChangePassword') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { LOGIN } from '@/router/route-types';
import { get } from 'lodash';
import { resetPassword } from '@/services/auth';

export default {
  name: 'FormChangePasswordAfterReset',

  mixins: [validationMixin],

  props: {
    token: {
      type: String,
      required: true
    }
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        minLength: minLength(6)
      }
    }
  },

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) {
        return errors;
      }
      if (!this.$v.form.password.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      if (!this.$v.form.password.minLength) {
        errors.push(this.$t('text.PasswordTooShort'));
      }
      return errors;
    },

    password2Errors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) {
        return errors;
      }
      if (!this.$v.form.password.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      if (!this.$v.form.password2.minLength) {
        errors.push(this.$t('text.PasswordTooShort'));
      }
      if (this.$v.form.password.$model !== this.$v.form.password2.$model) {
        errors.push(this.$t('text.PasswordDoNotMatch'));
      }
      return errors;
    }
  },

  data() {
    return {
      alert: {
        isVisible: false,
        message: '',
        color: '',
        type: 'success'
      },
      form: {
        password: '',
        password2: ''
      },
      isLoading: false
    };
  },

  methods: {
    resetPassword,

    async doSave() {
      try {
        this.isLoading = true;
        const result = await this.resetPassword({ token: this.token, password: this.form.password });
        const message = get(result, 'message');
        const data = get(result, 'public');

        // Error
        if (message) {
          this.alert.message = message.substring(0, 40);
          this.alert.isVisible = true;
          this.alert.color = 'error';
          this.alert.type = 'error';
          return false;
        }

        // Success
        if (data) {
          this.alert.message = this.$t('text.PasswordChangeSuccess');
          this.alert.isVisible = true;

          this.$v.$reset();
          this.form.password = null;
          this.form.password2 = null;

          this.$router.push({ name: LOGIN });
        }
      } catch (error) {
        //
      } finally {
        this.isLoading = false;
      }
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        // Show validation errors
      } else {
        // do your submit logic here
        this.doSave();
      }
    }
  }
};
</script>
