<template>
  <v-alert text prominent type="info" border="left" icon="mdi-airplane">
    {{ $t('error.noAircraftSelected') }}
  </v-alert>
</template>

<script>
export default {
  name: 'AlertNoAircraft'
};
</script>

<style lang="scss" scoped></style>
