import client from '@/config/directus.api';

const COLLECTION = 'countries';

/**
 * Get countries
 */
export function getCountries() {
  return client
    .items(COLLECTION)
    .readByQuery({
      sort: 'sort',
      limit: -1
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
