export const DASHBOARD = 'dashboard';

/** * Login/Logout */
export const LOGIN = 'login';
export const LOGOUT = 'logout';

/** * Register */
export const REGISTER = 'register';
export const REGISTER_COMPLETE = 'register-complete';

/** * Password */
export const RESET_PASSWORD = 'reset-password';
export const CHANGE_PASSWORD = 'change-password';
export const CHANGE_PASSWORD_COMPLETE = 'change-password-complete';

/** * Settings */
export const SETTINGS = 'settings';
export const SETTINGS_COMPANY = 'company-settings';
export const SETTINGS_USER = 'user-settings';
export const SETTINGS_CHANGE_PASSWORD = 'settings-change-password';
export const SETTINGS_SWITCH_ROLE = 'settings-switch-role';

/** * Support */
export const FAQ = 'faq';

/* User */
export const USERS = 'users';
export const USERS_OVERVIEW = 'users-overview';

/* Lifecycle */
export const LIFECYCLE = 'lifecycle';

/* Boardbook */
export const BOARDBOOK = 'boardbook';

/* Documents */
export const DOCUMENT = 'document';
// export const DOCUMENT_AIRWORTHINESS_REVIEW = 'document-airworthiness-review';
// export const DOCUMENT_IHP = 'document-ihp';
// export const DOCUMENT_ARC = 'document-arc';
// export const DOCUMENT_REGISTRATION_FORM = 'document-registration-form';
// export const DOCUMENT_INSURANCE_POLICY = 'document-insurance-policy';
// export const DOCUMENT_AIRWORTHINESS_CERTIFICATION = 'document-airworthiness-certification';

/* Reports */
export const REPORT = 'report';
export const REPORT_EQUIPMENT = 'report-equipment';
export const REPORT_FINDINGS = 'report-findings';
export const REPORT_WEIGHING = 'report-weighing';
export const REPORT_FLIGHT = 'report-flight';
export const REPORT_ADJUSTMENT = 'report-adjustment';
export const REPORT_COMPENSATION = 'report-compensation';

/* Lists */
export const LIST = 'list';
export const LIST_EQUIPMENT = 'list-equipment';
export const LIST_AD = 'list-airworthiness-directice';

/* Operating hours */
export const OPERATINGHOURS = 'operatinghours';

/* Manuals */
export const MANUALS = 'manuals';
