import i18n from '@/i18n/index';

export default {
  HIDE_LOADING(state) {
    state.app.loading = false;
  },

  SET_APP_LANGUAGE(state, payload) {
    i18n.locale = payload.language;
    state.app.language = payload.language;
  },

  SET_COUNTRIES(state, payload) {
    state.countries = payload;
  },

  SET_TOGGLE_LOADING(state) {
    state.app.loading = !state.app.loading;
  }
};
