import Vue from 'vue';
import { Directus, LocalStorage } from '@directus/sdk';
import { get } from 'lodash';
import { MODULE_NAME as SNACKBAR_MODULE } from '@/store/modules/snackbar';
import { clearUserData, logout } from '@/services/auth';

const client = new Directus(process.env.VUE_APP_API, {
  auth: {
    mode: 'json'
  },
  storage: {
    mode: LocalStorage
  }
});

/**
 * Interceptors for AXIOS
 */
client.transport.axios.interceptors.response.use(
  response => {
    // All fine
    return response;
  },
  error => {
    const response = get(error, 'response');
    const errorResponse = get(error, 'response.data.errors', []);
    const errorStatus = get(error, 'response.status');
    const responseURL = get(error, 'request.responseURL', '');
    console.log('error plugin', { error }, response, errorResponse, errorStatus);

    if (errorStatus === 401 && responseURL.includes('/users/me?')) {
      Vue.prototype.$notify({
        type: 'error',
        message: 'Session ist abgelaufen. Bitte erneut anmelden.',
        showClose: false
      });
      logout();
      clearUserData();
      Vue.prototype.$router.push({ path: '/login' });
    }

    // if (errorStatus === 401) {
    //   if (!responseURL.includes('auth/login')) {
    //     logout();
    //   }
    //   clearUserData();
    // }

    /**
     * Show all kind of error from backend
     */
    if (errorResponse.length) {
      errorResponse.forEach(item => {
        const { message } = item;
        if (!message) {
          return;
        }

        Vue.prototype.$store.dispatch(`${SNACKBAR_MODULE}/OPEN`, {
          text: message,
          timeout: 12000,
          color: 'black'
        });
      });
    }

    return Promise.reject(error);
  }
);

export default client;
