import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken3, //'#3775E6',
        secondary: colors.deepOrange.darken1, //'#DB3069',
        accent: colors.blue.accent3, //'#F5D547',
        error: colors.red.darken1,
        info: colors.lightBlue.accent4,
        success: colors.lightGreen.darken1,
        warning: colors.orange.lighten1 //'#b71c1c'
      }
    }
  }
});
