import { get } from 'lodash';
import * as User from '@/services/user';

export default {
  namespaced: true,

  state: {
    all: [],
    current: {
      id: null,
      company: {},
      email: '',
      first_name: '',
      last_name: '',
      status: '',
      role: {},
      is_company_admin: false,
      accept_privacy_policy: false,
      roles: [],
      language: 'en'
    },
    filter: {
      searchString: ''
    },
    isLoading: false
  },

  getters: {
    all: state => {
      let result = state.all.slice();
      const searchString = state.filter.searchString.toLowerCase();

      // Respect search string
      if (searchString && searchString.length > 1) {
        result = state.all.filter(user => {
          return (
            user.first_name.toLowerCase().includes(searchString) ||
            user.last_name.toLowerCase().includes(searchString) ||
            user.abbreviation.toLowerCase().includes(searchString)
          );
        });
      }

      return result;
    },

    company: state =>
      get(state.current, 'company', {
        name: ''
      }),

    currentUser: state => state.current,

    currentUserLanguage: state => state.current.language,

    currentUserRoles: state => {
      const result = [];
      if (state.current.roles.length) {
        state.current.roles.forEach(role => {
          const { directus_roles_id } = role;
          if (directus_roles_id) {
            result.push(directus_roles_id);
          }
        });
      }
      result.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return result;
    },

    isCompanyAdmin: state => state.current.is_company_admin,

    isLoading: state => state.isLoading,

    userById: state => id => {
      return state.all.find(user => {
        return parseInt(user.id) === parseInt(id);
      });
    }
  },

  mutations: {
    DELETE_USER(state, id) {
      // Get index of template item, otherwhise return -1
      const index = state.all.findIndex(item => {
        const itemId = get(item, 'id');

        if (!itemId || !id) {
          return -1;
        }

        return itemId === id;
      });

      // Remove item
      if (index !== -1) {
        const items = state.all.slice();
        items.splice(index, 1);
        state.all = items;
      }
    },

    SET_FILTER(state, payload) {
      state.filter.searchString = payload.searchString;
    },

    SET_USER(state, payload) {
      // Get index of template item, otherwhise return -1
      const index = state.all.findIndex(item => {
        const itemId = get(item, 'id');
        const payloadId = get(payload, 'id');

        if (!itemId || !payloadId) {
          return -1;
        }

        return parseInt(itemId) === parseInt(payloadId);
      });

      // Update or push item to the store
      if (index !== -1) {
        const items = state.all.slice();
        const newItem = Object.assign({}, items[index], payload); // Keep the relations
        newItem['is_company_admin'] = newItem['is_company_admin'] === '1' ? true : false;
        items.splice(index, 1, newItem);
        state.all = items;
      } else {
        state.all.push(payload);
      }
    },

    SET_CURRENT_USER(state, payload) {
      Object.assign(state.current, payload);
    },

    SET_COMPANY(state, payload) {
      Object.assign(state.current.company, payload);
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_PRIVACYPOLICY(state, payload) {
      state.current.accept_privacy_policy = payload;
    },

    SET_USERS(state, payload) {
      state.all = payload.slice();
    }
  },

  actions: {
    async CHANGE_PASSWORD({ commit }, payload) {
      try {
        commit('SET_LOADING', true);
        const response = await User.changePassword(payload);
        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async GET_CURRENT_USER({ commit }) {
      try {
        commit('SET_LOADING', true);
        const response = await User.getMe();
        commit('SET_CURRENT_USER', response);
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async GET_USERS({ commit }) {
      try {
        commit('SET_LOADING', true);
        const { data = [] } = await User.getUsers();
        commit('SET_USERS', data);
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async UPDATE_PRIVACY_POLICY({ commit }, payload) {
      try {
        commit('SET_LOADING', true);
        const response = await User.updateUserById(payload.id, payload);
        const data = get(response, 'data');

        // Only update defined fields
        if (data) {
          commit('SET_PRIVACYPOLICY', payload.accept_privacy_policy);
        }

        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async UPDATE_CURRENT_USER({ commit, state }, payload) {
      try {
        commit('SET_LOADING', true);
        const response = await User.updateCurrentUser(payload);
        const { id } = response;

        // Only update defined fields
        if (id) {
          const user = new User.User(response);
          if (id === state.current.id) {
            commit('SET_CURRENT_USER', user);
          } else {
            commit('SET_USER', user);
          }
        }

        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'user';
