import { SETTINGS, SETTINGS_COMPANY, SETTINGS_USER, SETTINGS_CHANGE_PASSWORD, SETTINGS_SWITCH_ROLE } from '@/router/route-types';
import AppLayout from '@/layouts/AppLayout.vue';
import Settings from '@/views/Settings/Settings.vue';

export default {
  path: '/settings',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: SETTINGS,
      redirect: 'user',
      component: Settings,
      meta: {
        auth: true,
        title: 'Einstellungen'
      },
      children: [
        {
          path: 'company',
          name: SETTINGS_COMPANY,
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsCompany.vue'),
          meta: {
            auth: true,
            title: 'Einstellungen'
          }
        },
        {
          path: 'user',
          name: SETTINGS_USER,
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsUser.vue'),
          meta: {
            auth: true,
            title: 'Einstellungen'
          }
        },
        {
          path: 'change-password',
          name: SETTINGS_CHANGE_PASSWORD,
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsChangePassword.vue'),
          meta: {
            auth: true,
            title: 'Einstellungen'
          }
        },
        {
          path: 'switch-role',
          name: SETTINGS_SWITCH_ROLE,
          component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/SettingsSwitchRole.vue'),
          meta: {
            auth: true,
            title: 'Einstellungen'
          }
        }
      ]
    }
  ]
};
