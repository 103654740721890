<template>
  <v-navigation-drawer app clipped :mobile-breakpoint="mobileBreakpoint" v-model="isVisible" height="100%">
    <div class="d-flex flex-column" style="height: 100%;">
      <div class="hidden-md-and-up px-5 mb-6">
        <SelectCustomerAircraft />
      </div>

      <v-divider></v-divider>

      <v-list dense nav class="mt-4">
        <div v-for="item in items" :key="item.title">
          <!-- Has children -->
          <v-list-group v-if="item.children" :value="false" :prepend-icon="item.icon" :ripple="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="level2 in item.children"
              :key="level2.title"
              :to="level2.route"
              :ripple="false"
              link
              exact
              color="primary"
              flat
              class="pl-16"
            >
              <v-list-item-content>
                <v-list-item-title :class="{ 'ml-3': level2.isIndented }">{{ $t(level2.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- No children -->
          <v-list-item v-else :to="item.route" link exact color="primary" flat :title="$t(item.title)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <v-spacer></v-spacer>

      <div class="mb-4 hidden-sm-and-up">
        <UserProfile />
      </div>

      <v-divider></v-divider>

      <div class="hidden-md-and-up px-2 my-2">
        <v-btn icon :title="$t('btn.addFeedback')" class="mr-2">
          <v-icon>mdi-comment-multiple-outline</v-icon>
        </v-btn>

        <v-btn icon :title="$t('btn.showNotifications')" class="">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { BOARDBOOK, DASHBOARD, LIFECYCLE, DOCUMENT, REPORT, LIST_EQUIPMENT, LIST_AD, OPERATINGHOURS, MANUALS } from '@/router/route-types';
import { MODULE_NAME as MENU_MODULE } from '@/store/modules/menu';
import SelectCustomerAircraft from '@/components/Select/SelectCustomerAircraft';
import UserProfile from '@/components/Misc/UserProfile';

export default {
  name: 'AppAside',

  components: {
    SelectCustomerAircraft,
    UserProfile
  },

  computed: {
    ...mapGetters({
      isCollapsed: `${MENU_MODULE}/isCollapsed`,
      mobileBreakpoint: `${MENU_MODULE}/mobileBreakpoint`
    })
  },

  data() {
    return {
      isVisible: null,
      items: [
        { title: 'nav.Dashboard', icon: 'mdi-view-dashboard', route: { name: DASHBOARD } },
        { title: 'nav.Boardbook', icon: 'mdi-state-machine', route: { name: BOARDBOOK } },
        { title: 'nav.Lifecycle', icon: 'mdi-history', route: { name: LIFECYCLE } },
        {
          title: 'nav.Overviews',
          icon: 'mdi-clock',
          children: [
            { title: 'nav.OperatingHours', icon: 'mdi-clock', route: { name: OPERATINGHOURS } },
            { title: 'nav.EquipmentList', icon: 'mdi-chart-bar', route: { name: LIST_EQUIPMENT } },
            { title: 'nav.AirworthinessDirectice', icon: 'mdi-chart-bar', route: { name: LIST_AD } }
          ]
        },

        {
          title: 'nav.Reports',
          icon: 'mdi-chart-bar',
          route: { name: REPORT },
          children: [
            { title: 'nav.reports.All', icon: 'mdi-chart-bar', route: { name: REPORT }, isIndented: false },
            {
              title: 'nav.FindingsReport',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'report-of-findings' } },
              isIndented: true
            },
            {
              title: 'nav.FlightReport',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'flight-report' } },
              isIndented: true
            },
            {
              title: 'nav.CompensationReport',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'compensation-reports' } },
              isIndented: true
            },
            {
              title: 'nav.WeighingReport',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'weight-and-balance-report' } },
              isIndented: true
            },
            {
              title: 'nav.AdjustmentReport',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'adjustment-reports' } },
              isIndented: true
            },
            {
              title: 'nav.StandRunReports',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'standrun-reports' } },
              isIndented: true
            },
            {
              title: 'nav.InspectionReports',
              icon: 'mdi-chart-bar',
              route: { name: REPORT, query: { docType: 'inspection-reports' } },
              children: [
                { title: 'nav.OperatingHours', icon: 'mdi-clock', route: { name: OPERATINGHOURS } },
                { title: 'nav.EquipmentList', icon: 'mdi-chart-bar', route: { name: LIST_EQUIPMENT } },
                { title: 'nav.AirworthinessDirectice', icon: 'mdi-chart-bar', route: { name: LIST_AD } }
              ],
              isIndented: true
            }
          ]
        },
        {
          title: 'nav.Documents',
          icon: 'mdi-file',
          route: { name: DOCUMENT },
          children: [
            {
              title: 'nav.DocumentsAll',
              icon: 'mdi-chart-bar',
              route: { name: DOCUMENT, query: { tags: ['document'], title: 'nav.DocumentsAll' } }
            },
            {
              title: 'nav.amp',
              icon: 'mdi-chart-bar',
              route: { name: DOCUMENT, query: { tags: ['aircraft-maintenance-program'], title: 'nav.amp' } },
              isIndented: true
            },
            {
              title: 'nav.AirworthinessCertification',
              icon: 'mdi-chart-bar',
              route: {
                name: DOCUMENT,
                query: { tags: ['certificate-of-airworthiness'], title: 'nav.AirworthinessCertification' }
              },
              isIndented: true
            },
            {
              title: 'nav.certificateOfRegistration',
              icon: 'mdi-chart-bar',
              route: {
                name: DOCUMENT,
                query: { tags: ['aircraft-registration-form'], title: 'nav.certificateOfRegistration' }
              },
              isIndented: true
            },
            {
              title: 'nav.AeronauticalRadioCertificate',
              icon: 'mdi-chart-bar',
              route: {
                name: DOCUMENT,
                query: { tags: ['aeronautical-radio-certificate'], title: 'nav.AeronauticalRadioCertificate' }
              },
              isIndented: true
            },
            {
              title: 'nav.tcds',
              icon: 'mdi-chart-bar',
              route: { name: DOCUMENT, query: { tags: ['type-certificate-data-sheet'], title: 'nav.tcds' } },
              isIndented: true
            },
            {
              title: 'nav.InsurancePolicy',
              icon: 'mdi-chart-bar',
              route: { name: DOCUMENT, query: { tags: ['insurance-certificate'], title: 'nav.InsurancePolicy' } },
              isIndented: true
            },
            {
              title: 'nav.noiseCertificate',
              icon: 'mdi-chart-bar',
              route: { name: DOCUMENT, query: { tags: ['noise-certificate'], title: 'nav.noiseCertificate' } },
              isIndented: true
            }
          ]
        },
        {
          title: 'nav.Manuals',
          icon: 'mdi-file',
          route: { name: MANUALS },
          children: [
            {
              title: 'nav.manuals.All',
              icon: 'mdi-chart-bar',
              route: { name: MANUALS, query: { title: 'nav.manuals.All' } },
              isIndented: false
            },
            {
              title: 'nav.manuals.Airplane',
              icon: 'mdi-chart-bar',
              route: { name: MANUALS, query: { tags: ['manual', 'aircraft'], title: 'nav.manuals.Airplane' } },
              isIndented: true
            },
            {
              title: 'nav.manuals.Engine',
              icon: 'mdi-chart-bar',
              route: { name: MANUALS, query: { tags: ['manual', 'engine'], title: 'nav.manuals.Engine' } },
              isIndented: true
            },
            {
              title: 'nav.manuals.Propeller',
              icon: 'mdi-chart-bar',
              route: { name: MANUALS, query: { tags: ['manual', 'propeller'], title: 'nav.manuals.Propeller' } },
              isIndented: true
            },
            {
              title: 'nav.manuals.Component',
              icon: 'mdi-chart-bar',
              route: { name: MANUALS, query: { tags: ['manual', 'component'], title: 'nav.manuals.Component' } },
              isIndented: true
            }
          ]
        }
      ]
    };
  },

  watch: {
    isCollapsed() {
      this.isVisible = this.isCollapsed;
    },

    isVisible() {
      if (this.isVisible !== this.isCollapsed) {
        this.$store.commit(`${MENU_MODULE}/COLLAPSE`, this.isVisible);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
