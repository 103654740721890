<template>
  <div class="view">
    <div class="view__inner view__inner--shadow">
      <div class="text-center">
        <!-- <SVGLogo class="logo" /> -->
      </div>

      <h3 class="mt-2">{{ $t('LoginTo') }} {{ title }}</h3>

      <!-- Login Form -->
      <FormLogin class="mt-2" />

      <p class="text-right mt-5">
        <router-link :to="{ name: RESET_PASSWORD }">
          {{ $t('ForgotPassword') }}
        </router-link>
      </p>
    </div>

    <!-- Footer -->
    <p class="view__footer">
      <a href="https://www.flightbook.aero/impressum.html" target="_blank" :title="$t('Imprint')" class="color-gray font--small">{{
        $t('Imprint')
      }}</a>
      <a href="https://www.flightbook.aero/datenschutz.html" target="_blank" :title="$t('PrivacyPolicy')" class="color-gray font--small">{{
        $t('PrivacyPolicy')
      }}</a>
    </p>
  </div>
</template>

<script>
import { REGISTER, RESET_PASSWORD } from '@/router/route-types';
import FormLogin from '@/components/Form/Auth/FormLogin';

export default {
  name: 'Login',

  components: {
    FormLogin
  },

  computed: {
    REGISTER() {
      return REGISTER;
    },

    RESET_PASSWORD() {
      return RESET_PASSWORD;
    },

    title() {
      return process.env.VUE_APP_TITLE;
    }
  }
};
</script>

<style lang="scss" scoped>
.view {
  flex: 1;

  &__inner {
    background-color: #ffffff;
    max-width: 24rem;
    padding: 2rem;

    @media screen and (min-width: $screen-md) {
      max-width: 25rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    > * {
      margin: 0 1rem;
    }
  }
}

.logo {
  max-height: 4rem;
  width: auto;

  @media screen and (min-width: $screen-md) {
    max-height: 5rem;
  }
}
</style>
