/**
 * @returns browsers language code
 */
export function getLanguage() {
  let language = window.navigator.userLanguage || window.navigator.language;
  if (language === null) {
    language = 'EN';
  }

  return language.substr(0, 2).toUpperCase();
}

/**
 * @returns Boolean
 */
export function isDeviceXS() {
  return window.matchMedia('(max-width: 767px)').matches;
}

/**
 * @returns Boolean
 */
export function isDeviceSM() {
  return window.matchMedia('(min-width: 768px) and (max-width: 991px)').matches;
}

/**
 * @returns Boolean
 */
export function isDeviceMD() {
  return window.matchMedia('(min-width: 992px) and (max-width: 1199px)').matches;
}

/**
 * @returns Boolean
 */
export function isDeviceLG() {
  return window.matchMedia('(min-width: 1200px) and (max-width: 1919px)').matches;
}

/**
 * @returns Boolean
 */
export function isDeviceXL() {
  return window.matchMedia('(min-width: 1920px)').matches;
}

/**
 Target IE 10 with JavaScript and CSS property detection.

 # 2013 by Tim Pietrusky
 # timpietrusky.com
 *
 * List of IE CSS stuff:
 * http://msdn.microsoft.com/en-us/library/ie/hh869403(v=vs.85).aspx
* */
export function isBrowserIE() {
  // IE 10 only CSS properties
  const ie10Styles = [
    'msTouchAction',
    'msWrapFlow',
    'msWrapMargin',
    'msWrapThrough',
    'msOverflowStyle',
    'msScrollChaining',
    'msScrollLimit',
    'msScrollLimitXMin',
    'msScrollLimitYMin',
    'msScrollLimitXMax',
    'msScrollLimitYMax',
    'msScrollRails',
    'msScrollSnapPointsX',
    'msScrollSnapPointsY',
    'msScrollSnapType',
    'msScrollSnapX',
    'msScrollSnapY',
    'msScrollTranslation',
    'msFlexbox',
    'msFlex',
    'msFlexOrder'
  ];

  const ie11Styles = ['msTextCombineHorizontal'];

  /*
   * Test all IE only CSS properties
   */
  const d = document;
  const b = d.body;
  const s = b.style;
  let ieVersion = null;
  let property;

  // Test IE10 properties
  for (let i = 0; i < ie10Styles.length; i++) {
    property = ie10Styles[i];

    if (s[property] != undefined) {
      ieVersion = 'ie10';
    }
  }

  // Test IE11 properties
  for (let i = 0; i < ie11Styles.length; i++) {
    property = ie11Styles[i];

    if (s[property] != undefined) {
      ieVersion = 'ie11';
    }
  }

  if (ieVersion) {
    b.className = ieVersion;
  }

  return ieVersion;
}

export function isBrowserEdge() {
  return typeof CSS !== 'undefined' && CSS.supports('(-ms-ime-align:auto)');
}
