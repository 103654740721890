<template>
  <v-dialog
    v-model="isVisible"
    :width="width"
    :fullscreen="fullscreen"
    :persistent="true"
    v-resize="handleResize"
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          {{ $t('headline.addFlightlogEntry') }}
        </span>

        <v-spacer></v-spacer>

        <v-btn icon @click.prevent="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <FormFlightlog @cancel="$emit('cancel')" @save="$emit('cancel')" v-if="isVisible" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormFlightlog from '@/components/Form/Flightlog/FormFlightlog.vue';
import * as Browser from '@/services/browser';

export default {
  name: 'DialogFlightlog',

  components: {
    FormFlightlog
  },

  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },

  computed: {},

  data() {
    return {
      fullscreen: false,
      width: '100%'
    };
  },

  methods: {
    handleResize() {
      if (Browser.isDeviceXS()) {
        this.width = '100%';
        this.fullscreen = true;
      } else if (Browser.isDeviceSM()) {
        this.width = '90%';
        this.fullscreen = false;
      } else if (Browser.isDeviceMD() || Browser.isDeviceLG()) {
        this.width = '80%';
        this.fullscreen = false;
      } else {
        this.width = '60%';
        this.fullscreen = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
