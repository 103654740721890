/* eslint-disable no-empty-pattern */
import { logout, requestPasswordReset } from '@/services/auth';

export default {
  namespaced: true,

  state: {},

  getters: {
    isAuth: () => {
      const authToken = localStorage.getItem('auth_token');
      const authExpires = localStorage.getItem('auth_expires');
      const authExpiresAt = localStorage.getItem('auth_expires_at');
      return authToken && authToken.length > 0 && authExpires && authExpires.length > 0 && authExpiresAt && authExpiresAt.length > 0;
    }
  },

  mutations: {
    async CLEAR() {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('auth_expires');
      localStorage.removeItem('auth_expires_at');
    }
  },

  actions: {
    LOGOUT({ commit }) {
      return logout()
        .then(response => {
          return response;
        })
        .catch(err => err)
        .finally(() => {
          commit('CLEAR');
        });
    },

    RESET_PASSWORD({}, payload) {
      return requestPasswordReset(payload)
        .then(response => response)
        .catch(err => err);
    }
  }
};

export const MODULE_NAME = 'auth';
