<template>
  <div class="view">
    <div class="view__inner view__inner--shadow">
      <div class="text-center">
        <!-- <SVGLogo class="logo" /> -->
      </div>

      <h3 class="mt-3">{{ $t('ResetPassword') }}</h3>

      <FormResetPassword class="mt-4" :username="email" />

      <Separator class="mt-6" :show-line="true" :text="$t('or')"></Separator>

      <p class="text-center mt-2">
        <router-link :to="{ name: LOGIN }" class="w-100 mt-4">
          {{ $t('Login') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { LOGIN } from '@/router/route-types';
import FormResetPassword from '@/components/Form/Auth/FormResetPassword';
import Separator from '@/components/Misc/Separator';
// import SVGLogo from '@/assets/img/logo-webplus.svg';
import { get } from 'lodash';

export default {
  name: 'ResetPassword',

  components: {
    FormResetPassword,
    Separator
    // SVGLogo
  },

  computed: {
    email() {
      return get(this.$route.query, 'email', '');
    },
    LOGIN() {
      return LOGIN;
    }
  }
};
</script>

<style lang="scss" scoped>
.view {
  flex: 1;

  &__inner {
    background-color: #ffffff;

    @media screen and (min-width: $screen-md) {
      max-width: 30rem;
    }
  }
}

.logo {
  max-height: 4rem;
  width: auto;

  @media screen and (min-width: $screen-md) {
    max-height: 5rem;
  }
}
</style>
