<template>
  <v-app-bar app color="white" light flat dense clipped-left clipped-right>
    <v-app-bar-nav-icon @click="onClickNav" class="hidden-md-and-up"></v-app-bar-nav-icon>

    <v-toolbar-title :class="{ 'pl-0': $vuetify.breakpoint.mdAndUp }">
      <div class="logo-container">
        {{ title }}
      </div>
    </v-toolbar-title>

    <div class="d-flex justify-center spacer" v-if="$vuetify.breakpoint.mdAndUp">
      <div class="select-aircraft-container">
        <SelectCustomerAircraft />
      </div>
    </div>

    <v-spacer v-else></v-spacer>

    <v-btn icon :title="$t('btn.addFlightlogEntry')" class="btn-flightbook-entry" @click.prevent="onClickBtnFlightbook">
      <v-icon color="blue darken-2">mdi-clock-plus</v-icon>
    </v-btn>

    <v-btn icon :title="$t('btn.addFeedback')" class="btn-feedback">
      <v-icon>mdi-comment-multiple-outline</v-icon>
    </v-btn>

    <v-btn icon :title="$t('btn.showNotifications')" class="btn-notifications">
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <div class="user-profile__wrapper">
      <UserProfile />
    </div>

    <!-- Shared components -->
    <DialogFlightlog :is-visible="isDialogFlightlogVisibile" @cancel="isDialogFlightlogVisibile = false" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_NAME as MENU_MODULE } from '@/store/modules/menu';
import SelectCustomerAircraft from '@/components/Select/SelectCustomerAircraft';
import UserProfile from '@/components/Misc/UserProfile';
import DialogFlightlog from '@/components/Dialog/DialogFlightlog.vue';

export default {
  name: 'AppBar',

  components: {
    DialogFlightlog,
    SelectCustomerAircraft,
    UserProfile
  },

  computed: {
    ...mapGetters({
      menuIsCollapsed: `${MENU_MODULE}/isCollapsed`
    }),

    title() {
      return process.env.VUE_APP_TITLE;
    }
  },

  data() {
    return {
      isDialogFlightlogVisibile: false
    };
  },

  methods: {
    onClickNav() {
      this.$store.commit(`${MENU_MODULE}/TOGGLE_COLLAPSE`);
    },

    onClickBtnFlightbook() {
      this.isDialogFlightlogVisibile = true;
    }
  }
};
</script>

<style lang="scss">
.v-toolbar__content {
  border-bottom: 1px solid var(--c-medium-gray);
}

.user-profile__wrapper {
  display: none;

  @media screen and (min-width: 600px) {
    display: block;
  }
}

.select-aircraft-container {
  margin-left: 6rem;
  max-width: 17rem;
}

.logo-container {
  background-color: var(--c-brand);
  color: var(--c-extra-light-gray);
  padding: 0.125rem 1rem;

  border: 2px solid var(--c-brand-secondary);

  font-weight: 700;
  font-style: italic;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.btn-feedback,
.btn-notifications {
  display: none;

  @media screen and (min-width: 420px) {
    display: block;
  }
}
</style>
