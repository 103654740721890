import AppLayout from '@/layouts/AppLayout.vue';

import { BOARDBOOK } from '@/router/route-types';

export default {
  path: '/boardbook',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: BOARDBOOK,
      component: () => import(/* webpackChunkName: "boardbook" */ '@/views/Boardbook/BoardbookIndex.vue'),
      meta: {
        auth: true,
        title: 'Boardbook'
      }
    }
  ]
};
