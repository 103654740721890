<template>
  <v-simple-table class="table__documents-overview">
    <template v-slot:default>
      <thead v-if="showHeader">
        <tr>
          <th>
            {{ $t('Title') }}
          </th>
          <th style="max-width: 11rem;">
            {{ $t('Revision') }}
          </th>
          <th style="max-width: 16rem;">
            {{ $t('Date') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- No data -->
        <tr v-if="!items.length">
          <th colspan="8">
            <v-alert text dense type="info" class="mt-4">
              {{ $t('hint.noData') }}
            </v-alert>
          </th>
        </tr>

        <!-- All Items -->
        <tr v-for="(item, i) in items" :key="item.id" :ref="`row-${i}`">
          <td style="width: 950px">
            <a
              :href="getAssetUrl(item.id) + '?access_token=' + authToken"
              class="file-item__link py-2"
              target="_blank"
              :title="$t('text.clickToOpenFile')"
            >
              <div class="d-flex">
                {{ item.title }}
              </div>
            </a>
          </td>
          <td style="width: 13rem;">
            <div class="d-flex">
              {{ item.revision }}
            </div>
          </td>
          <td style="width: 13rem;">
            <div class="d-flex">
              {{ $d(new Date(item.published_at), 'short') }}
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { getAssetUrl } from '@/services/utils';

export default {
  name: 'TableManualsDocumentTree',

  props: {
    items: {
      type: Array,
      required: true
    },

    showHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    authToken() {
      return localStorage.getItem('auth_token') || '';
    }
  },

  methods: {
    getAssetUrl
  }
};
</script>

<style lang="scss" scoped>
.file-item__link {
  font-weight: 400;
}
</style>
