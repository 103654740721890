import client from '@/config/directus.api';
import { uniqBy } from 'lodash';

const COLLECTION = 'directus_files';

/**
 * Get file by id
 *
 * @param {number} id
 */
export function getFile(id) {
  return client
    .getItems(COLLECTION, {
      filter: {
        id: id
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Get files
 * @param {Object} filter
 */
export function getFiles(filter = {}, limit = -1) {
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: [
        '*',
        'status.*',
        'parent.*',
        'parent.base_tags.base_tags_id.*',
        'cmp_tmp.components_id.*',
        // 'cmp_serialized.*',
        // 'cmp_unser.*',
        'companies.companies_id.*',
        'authorities.authorities_id.*',
        'bse_mnt_type.bse_mnt_types_id.*.*',
        'base_tags.base_tags_id.*.*',
        'easa_types.easa_types_id.*',
        'cust_aircraft.cust_aircrafts_id.*',
        'references.related_directus_files_id.*'
      ],
      filter,
      limit
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Get manuals
 *
 * @param   {Object}  payload
 * @param   {string}  payload.customerAircraftId
 * @param   {Array}  payload.tags
 *
 * @return  {Promise}
 */
export function getManuals({ customerAircraftId, tags }) {
  return client.transport.get('appfiles/manuals', {
    params: {
      customerAircraftId,
      tags
    }
  });
}

// @Todo: Remove if all went well with new endpoint /files/ad
// export function getADsForAircraft(filter = {}) {
//   return client
//     .items(COLLECTION)
//     .readByQuery({
//       fields: [
//         '*',
//         'status.name',
//         'status.translations.*',
//         'parent.*',
//         'cmp_tmp.*',
//         'cmp_serialized.*',
//         'cmp_unser.*',
//         'base_tags.base_tags_id.*',
//         'easa_types.easa_types_id.*',
//         'cust_aircraft.cust_aircrafts_id.*',
//         'references.related_directus_files_id.*',
//         'ad_sb_action.*',
//         'ad_sb_action.reference.*'
//       ],
//       filter,
//       limit: '-1',
//       sort: ['published_at']
//     })
//     .then(response => {
//       return response;
//     })
//     .catch(err => {
//       return err;
//     });
// }

export function getADsForAircraft({ download, customerAircraft, easaTypeName }) {
  return client.transport.get(`appfiles/ad`, {
    params: {
      download,
      customerAircraft,
      easaTypeName
    }
  });
}

/**
 * Delete file by id
 *
 * @param {number} id
 */
export function deleteFile(id) {
  return client
    .deleteItem(COLLECTION, id)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Update file
 *
 * @param {number} id
 * @param {Object} data
 */
export function updateFile(id, data) {
  return client
    .updateItem(COLLECTION, id, data)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Transform root file and add leaf files in a flatternd structure
 *
 * @param   {Array}  files
 *
 * @return  {Array}
 */
export function transformRootLeafsTwoLevel(files) {
  const result = [];

  files.forEach(file => {
    if (file.parent === null) {
      // Handle root file
      const rootFileExist = result.some(item => item.id === file.id);
      if (!rootFileExist) {
        file.children = [];
        result.push(file);
      }
    } else {
      // Handle childrens
      const rootFile = getRootFile(file, files);
      if (rootFile) {
        const rootFileIndex = result.findIndex(item => item.id === rootFile.id);

        if (rootFileIndex > -1) {
          result[rootFileIndex].children = uniqBy([...result[rootFileIndex].children, file], 'id');
        } else {
          result.push({
            ...file.parent,
            children: [file]
          });
        }
      }
    }
  });

  // Sort childrens by published_at
  const sorted = result.map(parentFile => {
    parentFile.children = parentFile.children.sort((a, b) => {
      if (a.published_at === null || b.published_at === null) return -1;

      const dateA = new Date(a.published_at).getTime();
      const dateB = new Date(b.published_at).getTime();

      if (dateA < dateB) {
        return 1;
      }
      if (dateA > dateB) {
        return -1;
      }
      return 0;
    });
    return parentFile;
  });

  return sorted;
}

/**
 * Get root file
 *
 * @param   {Object}  file
 * @param   {Array}  files
 *
 * @return  {Object|Null}
 */
function getRootFile(file, files) {
  if (!file || files.length === 0) return null;

  if (typeof file === 'string') {
    const found = files.find(item => item.id === file);
    if (found) {
      // file = Object.assign({}, found);
      file = JSON.parse(JSON.stringify(found));
    }
  }

  let result = null;
  if (file.parent === null) {
    result = file;
  } else {
    result = getRootFile(file.parent, files);
  }

  return result;
}

/**
 * Get translated item prop
 *
 * @param   {Object}        item          file item
 * @param   {String}        prop
 * @param   {String}        languageCode
 *
 * @return  {String}
 */
export function getTranslatedItemProp({ item, prop: propertyName, code: languageCode }) {
  if (!item || !propertyName || item.translations?.length === 0) return null;

  let result = null;

  item.translations.forEach(translation => {
    if (translation.languages_code === languageCode) {
      result = translation[propertyName];
    }
  });

  return result;
}
