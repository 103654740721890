export const de = [
  {
    de: 'Dashboard',
    placeholder: 'nav.Dashboard'
  },
  {
    de: 'Status',
    placeholder: 'nav.Status'
  },
  {
    de: 'Lebenslauf',
    placeholder: 'nav.Lifecycle'
  },
  {
    de: 'Betriebszeiten',
    placeholder: 'nav.OperatingHours'
  },
  {
    de: 'Listen',
    placeholder: 'nav.Lists'
  },
  {
    de: 'Berichte',
    placeholder: 'nav.Reports'
  },
  {
    de: 'Dokumente',
    placeholder: 'nav.Documents'
  },
  {
    de: 'Ausrüstungsbericht',
    placeholder: 'nav.EquipmentReport'
  },
  {
    de: 'Befundberichte',
    placeholder: 'nav.FindingsReport'
  },
  {
    de: 'IHP',
    placeholder: 'nav.IHP'
  },
  {
    de: 'Lufttüchtigkeitsprüfung',
    placeholder: 'nav.AirworthinessReview'
  },
  {
    de: 'Mein Profil',
    placeholder: 'nav.MyProfile'
  },
  {
    de: 'Abmelden',
    placeholder: 'nav.Logout'
  },
  {
    de: 'Einstellungen',
    placeholder: 'Settings'
  },
  {
    de: 'Passwort ändern',
    placeholder: 'nav.ChangePassword'
  },
  {
    de: 'Vorname',
    placeholder: 'FirstName'
  },
  {
    de: 'Nachname',
    placeholder: 'LastName'
  },
  {
    de: 'E-Mail',
    placeholder: 'EMail'
  },
  {
    de: 'Sprache',
    placeholder: 'Language'
  },
  {
    de: 'Bitte füllen Sie alle erforderlichen Felder aus',
    placeholder: 'text.EnterRequiredFields'
  },
  {
    de: 'Deutsch',
    placeholder: 'German'
  },
  {
    de: 'Englisch',
    placeholder: 'English'
  },
  {
    de: 'Passwort wiederholen',
    placeholder: 'RepeatPassword'
  },
  {
    de: 'Passwort',
    placeholder: 'Password'
  },
  {
    de: 'Schließen',
    placeholder: 'Close'
  },
  {
    de: 'Passwort ändern',
    placeholder: 'ChangePassword'
  },
  {
    de: 'Passwort ist zu kurz',
    placeholder: 'text.PasswordTooShort'
  },
  {
    de: 'Die Passwörter stimmen nicht überein',
    placeholder: 'text.PasswordDoNotMatch'
  },
  {
    de: 'Speichern',
    placeholder: 'Save'
  },
  {
    de: 'Passwort wurde erfolgreich geändert',
    placeholder: 'text.PasswordChangeSuccess'
  },
  {
    de: 'Anmelden',
    placeholder: 'Login'
  },
  {
    de: 'Falsches Format',
    placeholder: 'text.WrongFormat'
  },
  {
    de: 'Passwort zurücksetzen',
    placeholder: 'ResetPassword'
  },
  {
    de: 'Passwort wurde erfolgreich zurückgesetzt. <br> Sie erhalten in Kürze eine E-Mail mit weiteren Anweisungen.',
    placeholder: 'text.PasswordResetSuccess'
  },
  {
    de: 'Sie wurden erfolgreich abgemeldet',
    placeholder: 'text.LogoutSuccess'
  },
  {
    de: 'Passwort vergessen?',
    placeholder: 'ForgotPassword'
  },
  {
    de: 'Impressum',
    placeholder: 'Imprint'
  },
  {
    de: 'Anmelden zu',
    placeholder: 'LoginTo'
  },
  {
    de: 'oder',
    placeholder: 'or'
  },
  {
    de: 'Neues Passwort',
    placeholder: 'NewPassword'
  },
  {
    de: 'Erneut anmelden',
    placeholder: 'text.loginAgain'
  },
  {
    de: 'Einstellungen',
    placeholder: 'nav.Settings'
  },
  {
    de: 'Ausrüstung',
    placeholder: 'nav.EquipmentList'
  },
  {
    de: 'AD / LTA / TM',
    placeholder: 'nav.AirworthinessDirectice'
  },
  {
    de: 'Wägeberichte',
    placeholder: 'nav.WeighingReport'
  },
  {
    de: 'Flugberichte',
    placeholder: 'nav.FlightReport'
  },
  {
    de: 'Einstellberichte',
    placeholder: 'nav.AdjustmentReport'
  },
  {
    de: 'Kompensierberichte',
    placeholder: 'nav.CompensationReport'
  },
  {
    de: 'ARC',
    placeholder: 'nav.Arc'
  },
  {
    de: 'Eintragungsschein',
    placeholder: 'nav.RegistrationForm'
  },
  {
    de: 'Versicherungsschein',
    placeholder: 'nav.InsurancePolicy'
  },
  {
    de: 'Lufttüchtigkeitszeugnis',
    placeholder: 'nav.AirworthinessCertification'
  },
  {
    de: 'Betriebszeitenübersicht',
    placeholder: 'Overview Operating Hours'
  },
  {
    de: 'Handbücher',
    placeholder: 'nav.Manuals'
  },
  {
    de: 'Handbücher',
    placeholder: 'Manuals'
  },
  {
    de: 'Baugruppe',
    placeholder: 'Text.Assy'
  },
  {
    de: 'Ausrüstungsliste',
    placeholder: 'headline.EquipmentList'
  },
  {
    de: 'Kein Flugzeug ausgewählt.',
    placeholder: 'error.noAircraftSelected'
  },
  {
    de: 'Flugzeug auswählen',
    placeholder: 'label.selectAircraft'
  },
  {
    de: 'Bezeichnung',
    placeholder: 'Description'
  },
  {
    de: 'Muster',
    placeholder: 'Type'
  },
  {
    de: 'Einbauort',
    placeholder: 'Position'
  },
  {
    de: 'Hersteller',
    placeholder: 'Manufacturer'
  },
  {
    de: 'Aktionen',
    placeholder: 'Actions'
  },
  {
    de: 'Airworthiness Directive',
    placeholder: 'headline.airworthinessDirectice'
  },
  {
    de: 'Rev',
    placeholder: 'abbr.Revision'
  },
  {
    de: 'Keine Daten vorhanden',
    placeholder: 'hint.noData'
  },
  {
    de: 'Ausgabe',
    placeholder: 'Issue'
  },
  {
    de: 'Baureihe',
    placeholder: 'Series'
  },
  {
    de: 'Werk-Nr.',
    placeholder: 'SerialNumber'
  },
  {
    de: 'Betreff',
    placeholder: 'Subject'
  },
  {
    de: 'Fristen',
    placeholder: 'Deadline'
  },
  {
    de: 'Durchführung',
    placeholder: 'Implementation'
  },
  {
    de: 'alle',
    placeholder: 'all'
  },
  {
    de: 'empfohlen',
    placeholder: 'recommended'
  },
  {
    de: 'AD',
    placeholder: 'abbr.AD'
  },
  {
    de: 'TM',
    placeholder: 'abbr.TM'
  },
  {
    de: 'Werknummern',
    placeholder: 'SerialNumbers'
  },
  {
    de: 'Suchbegriff eingeben',
    placeholder: 'label.searchInput'
  },
  {
    de: 'Zutreffend auswählen',
    placeholder: 'label.selectApplicable'
  },
  {
    de: 'AD / LTA / TM Übersicht',
    placeholder: 'headline.airworthinessDirective'
  },
  {
    de: 'auf Anfrage',
    placeholder: 'on_request'
  },
  {
    de: 'Sucheingabe zu kurz',
    placeholder: 'error.searchQueryTooShort'
  },
  {
    de: 'Sortierreihenfolge ändern',
    placeholder: 'changeSortOrder'
  },
  {
    de: 'Filter',
    placeholder: 'toggleFilter'
  },
  {
    de: 'Filter',
    placeholder: 'Filters'
  },
  {
    de: 'Tabellenspalten auswählen',
    placeholder: 'label.selectedTableColumns'
  },
  {
    de: 'Titel aufsteigend',
    placeholder: 'sort.title.asc'
  },
  {
    de: 'Titel absteigend',
    placeholder: 'sort.title.desc'
  },
  {
    de: 'Datum aufsteigend',
    placeholder: 'sort.published_at.asc'
  },
  {
    de: 'Datum absteigend',
    placeholder: 'sort.published_at.desc'
  },
  {
    de: 'Datum aufsteigend',
    placeholder: 'sort.due_date.asc'
  },
  {
    de: 'Datum abstiegend',
    placeholder: 'sort.due_date.desc'
  },
  {
    de: 'Hersteller stellt keine Informationen bereit',
    placeholder: 'hint.noDataProvidedFromManufacturer'
  },
  {
    de: 'Teile-Nr.',
    placeholder: 'PartNumber'
  },
  {
    de: 'Tags auswählen',
    placeholder: 'label.selectTags'
  },
  {
    de: 'Klick!',
    placeholder: 'text.clickToOpenFile'
  },
  {
    de: 'Schließen',
    placeholder: 'btn.Close'
  },
  {
    de: 'Dokumententyp auswählen',
    placeholder: 'label.selectDocumentType'
  },
  {
    de: 'Reporttyp auswählen',
    placeholder: 'label.selectReportType'
  },
  {
    de: 'Dokumente',
    placeholder: 'headline.Documentlibrary'
  },
  {
    de: 'Anzeigemodus wählen',
    placeholder: 'label.selectDisplayMode'
  },
  {
    de: 'Tabelle',
    placeholder: 'mode.Table'
  },
  {
    de: 'Timeline',
    placeholder: 'mode.Timeline'
  },
  {
    de: 'Datum aufsteigend',
    placeholder: 'sort.date.asc'
  },
  {
    de: 'Datum absteigend',
    placeholder: 'sort.date.desc'
  },
  {
    de: 'Alle',
    placeholder: 'nav.DocumentsAll'
  },
  {
    de: 'Lot-Nr.',
    placeholder: 'LotNumber'
  },
  {
    de: 'Bordbuch',
    placeholder: 'nav.Boardbook'
  },
  {
    de: 'Bordbuch',
    placeholder: 'Boardbook'
  },
  {
    de: 'Übersichten',
    placeholder: 'nav.Overviews'
  },
  {
    de: 'Flugzeug',
    placeholder: 'nav.manuals.Airplane'
  },
  {
    de: 'Motor',
    placeholder: 'nav.manuals.Engine'
  },
  {
    de: 'Propeller',
    placeholder: 'nav.manuals.Propeller'
  },
  {
    de: 'Alle',
    placeholder: 'nav.manuals.All'
  },
  {
    de: 'Kennblatt',
    placeholder: 'nav.IdentificationSheet'
  },
  {
    de: 'Luftfunkzeugnis',
    placeholder: 'nav.AeronauticalRadioCertificate'
  },
  {
    de: 'Standlaufberichte',
    placeholder: 'nav.StandRunReports'
  },
  {
    de: 'Prüfberichte',
    placeholder: 'nav.InspectionReports'
  },
  {
    de: 'Alle',
    placeholder: 'nav.reports.All'
  },
  {
    de: 'Handbücher',
    placeholder: 'headline.Manuals'
  },
  {
    de: 'Komponenten',
    placeholder: 'nav.manuals.Component'
  },
  {
    de: 'Nur für  {type}',
    placeholder: 'label.onlyForEASAType'
  },
  {
    de: 'Kennblatt',
    placeholder: 'nav.tcds'
  },
  {
    de: 'Lärmschutzzeugnis',
    placeholder: 'nav.noiseCertificate'
  },
  {
    de: 'IHP',
    placeholder: 'nav.amp'
  },
  {
    de: 'Eintragungsschein',
    placeholder: 'nav.certificateOfRegistration'
  },
  {
    de: 'Handbücher anzeigen',
    placeholder: 'btn.showManuals'
  },
  {
    de: 'Alle Handbücher anzeigen',
    placeholder: 'btn.showAllManuals'
  },
  {
    de: 'Flugzeug auswählen',
    placeholder: 'label.selectAircraft'
  },
  {
    de: 'Registration',
    placeholder: 'Registration'
  },
  {
    de: 'Pilot',
    placeholder: 'label.selectPilot'
  },
  {
    de: 'Flugzeug auswählen',
    placeholder: 'placeholder.selectAircraft'
  },
  {
    de: 'Pilot',
    placeholder: 'placeholder.selectPilot'
  },
  {
    de: 'Co-Pilot',
    placeholder: 'label.selectCoPilot'
  },
  {
    de: 'Co-Pilot',
    placeholder: 'placeholder.selectCoPilot'
  },
  {
    de: 'Gäste',
    placeholder: 'label.cnt_guests'
  },
  {
    de: 'Gäste',
    placeholder: 'placeholder.cnt_guests'
  },
  {
    de: 'Startort',
    placeholder: 'label.fromIcao'
  },
  {
    de: 'Start',
    placeholder: 'placeholder.fromIcao'
  },
  {
    de: 'Landeort',
    placeholder: 'label.toIcao'
  },
  {
    de: 'Ende',
    placeholder: 'placeholder.toIcao'
  },
  {
    de: 'Datum',
    placeholder: 'label.Date'
  },
  {
    de: 'Off-Block',
    placeholder: 'label.offBlock'
  },
  {
    de: 'Off-Block',
    placeholder: 'placeholder.offBlock'
  },
  {
    de: 'Off-Block',
    placeholder: 'placeholder.offBlock'
  },
  {
    de: 'Start (UTC)',
    placeholder: 'label.takeOff'
  },
  {
    de: 'Take off',
    placeholder: 'placeholder.takeOff'
  },
  {
    de: 'Landung (UTC)',
    placeholder: 'label.ldg'
  },
  {
    de: 'Landung',
    placeholder: 'placeholder.ldg'
  },
  {
    de: 'On-Block',
    placeholder: 'label.onBlock'
  },
  {
    de: 'On-Block',
    placeholder: 'placeholder.onBlock'
  },
  {
    de: 'Anzahl Landungen',
    placeholder: 'label.countLandings'
  },
  {
    de: 'Anzahl Landungen',
    placeholder: 'placeholder.countLandings'
  },
  {
    de: 'Abbrechen',
    placeholder: 'btn.cancel'
  },
  {
    de: 'Speichern',
    placeholder: 'btn.save'
  },
  {
    de: 'Falscher Wert',
    placeholder: 'text.wrongValue'
  },
  {
    de: 'Flug wurde erfolgreich gespeichert',
    placeholder: 'text.flightlogEntrySavedSuccessfully'
  },
  {
    de: 'Benachrichtigungen anzeigen',
    placeholder: 'btn.showNotifications'
  },
  {
    de: 'Feedback erstellen',
    placeholder: 'btn.addFeedback'
  },
  {
    de: 'Flug hinzufügen',
    placeholder: 'btn.addFlightlogEntry'
  },
  {
    de: 'Flug hinzufügen',
    placeholder: 'headline.addFlightlogEntry'
  },
  {
    de: 'Motorzähler Start',
    placeholder: 'label.hoobsEng1Start'
  },
  {
    de: 'Motorzähler Ende',
    placeholder: 'label.hoobsEng1End'
  },
  {
    de: 'Cyclecounter Start',
    placeholder: 'label.countCycleEng1Start'
  },
  {
    de: 'Cyclecounter End',
    placeholder: 'label.countCycleEng1End'
  },
  {
    de: 'Landungen',
    placeholder: 'Landings'
  },
  {
    de: 'Flugzeit',
    placeholder: 'label.flighttime'
  },
  {
    de: 'Motorlaufzeit',
    placeholder: 'label.hobbsTime'
  },
  {
    de: 'Time since new',
    placeholder: 'label.timeSinceNew'
  },
  {
    de: 'Landings since new',
    placeholder: 'label.landingsSinceNew'
  },
  {
    de: 'Startort/Landeort',
    placeholder: 'label.fromToIcao'
  }
];
