import AppLayout from '@/layouts/AppLayout.vue';

import { LIST, LIST_EQUIPMENT, LIST_AD } from '@/router/route-types';

export default {
  path: '/list',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: LIST,
      component: () => import(/* webpackChunkName: "lists" */ '@/views/Lists/ListsIndex.vue'),
      meta: {
        auth: true,
        title: 'Lists'
      }
    },
    {
      path: 'equipment',
      name: LIST_EQUIPMENT,
      component: () => import(/* webpackChunkName: "lists" */ '@/views/Lists/ListEquipment.vue'),
      meta: {
        auth: true,
        title: 'Lists'
      }
    },
    {
      path: 'airworthiness-directice',
      name: LIST_AD,
      component: () => import(/* webpackChunkName: "lists" */ '@/views/Lists/ListAD.vue'),
      meta: {
        auth: true,
        title: 'Airworthiness Directice'
      }
    }
  ]
};
