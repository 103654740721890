import client from '@/config/directus.api';

const COLLECTION = 'translations';

/**
 * Get translation by language code
 *
 * @param {string} lang
 */
export function getTranslation(lang) {
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: [lang, 'placeholder'],
      limit: '-1'
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
