import AppLayout from '@/layouts/AppLayout.vue';

import { DASHBOARD } from '@/router/route-types';

export default {
  path: '',
  alias: '/dashboard',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: DASHBOARD,
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue'),
      meta: {
        auth: true,
        title: 'Dashboard'
      }
    }
  ]
};
