<template>
  <div class="app__layout-default">
    <div class="app__layout-default__content-wrapper">
      <!-- Main content goes here -->
      <router-view class="flex a-center valign-center flex-column"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
};
</script>

<style lang="scss" scoped>
.app__layout-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  background-color: var(--c-extra-light-gray);
  //background-image: url('/img/meta/cloudy.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;

  &__content-wrapper {
    width: 100%;
  }
}
</style>
