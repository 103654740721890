import Vue from 'vue';
import { MODULE_NAME as AUTH_MODULE } from '@/store/modules/auth';
import client from '@/config/directus.api';

/**
 * Clear user data
 *
 */
export function clearUserData() {
  Vue.prototype.$store.commit(`${AUTH_MODULE}/CLEAR`);
}

/**
 * Login user
 *
 * @param {object} payload
 * @returns {promise}
 */
export function login(payload) {
  return client.auth
    .login(payload, {
      refresh: {
        auto: true,
        time: 30000 // refesh the token 30 secs before the expiration
      }
    })
    .then(response => response)
    .catch(err => err);
}

/**
 * Logout user
 * Bug: https://github.com/directus/directus/pull/3337
 *
 * @returns {promise}
 */
export function logout() {
  return client.auth.logout();
}

/**
 * Request password reset
 *
 * @param {string} payload
 * @returns {promise}
 */
export function requestPasswordReset(payload) {
  const APP_URL = window.location.origin;

  return client.axios
    .post('auth/password/request', {
      email: payload,
      reset_url: `${APP_URL}/#/change-password`
    })
    .then(response => response)
    .catch(err => err);
}

/**
 * Reset password
 *
 * @param {Object} payload
 * @returns {promise}
 */
export function resetPassword(payload) {
  return client.axios
    .post('auth/password/reset', payload)
    .then(response => response)
    .catch(err => err);
}

/**
 * Refresh auth token
 *
 * @return  {String}
 */
export function refreshToken() {
  return client.auth.refresh();
}
