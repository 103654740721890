import client from '@/config/directus.api';

const COLLECTION = 'directus_users';

export class User {
  constructor(data) {
    this.id = data.id;
    // this.status = data.status;
    this.title = data.title;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.language = data.language || 'de-DE';
    // this.company = data.company;
    // this.role = data.role;
    this.password = data.password;
  }
}

export function getMe() {
  return client.users.me
    .read({
      fields: [
        '*',
        'role.*',
        'roles.directus_roles_id.id',
        'roles.directus_roles_id.name',
        'roles.directus_roles_id.description',
        'companies.companies_id.*'
      ]
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export async function updateCurrentUser(payload) {
  const user = new User(payload);
  delete user.id;
  try {
    const response = await client.users.me.update(user);
    return response;
  } catch (error) {
    return error;
  }
}

export function updateUserById(id, payload) {
  const user = new User(payload);
  return client
    .items(COLLECTION)
    .updateOne(id, user)
    .then(response => response)
    .catch(err => err);
}

export async function changePassword(payload) {
  try {
    const response = await client.users.me.update(payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getUsers() {
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: ['*'],
      filter: {
        status: {
          _in: 'draft,active,suspended'
        }
      }
    })
    .then(response => response)
    .catch(err => err);
}

export async function getUsersByCompany(id) {
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: ['*'],
      filter: {
        company: id,
        status: {
          in: 'draft,active'
        }
      }
    })
    .then(response => response)
    .catch(err => err);
}

export async function createUser(payload) {
  const user = new User(payload);
  try {
    const response = await client.items('directus_users').createOne(user);
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteUser(id) {
  try {
    const response = await client.items('directus_users').deleteOne(id);
    return response;
  } catch (error) {
    return error;
  }
}

export function switchUserRole(userId, roleId) {
  return client.transport.axios
    .patch(`/users/${userId}/switch-role`, { role: roleId })
    .then(response => response)
    .catch(err => err);
}
