<template>
  <div class="global-snackbar">
    <v-snackbar v-for="snackbar in snackbars" :key="snackbar.id" :value="isVisible(snackbar)" :color="snackbar.color">
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="onClose(snackbar)"> {{ $t('btn.Close') }} </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MODULE_NAME as SNACKBAR_MODULE } from '@/store/modules/snackbar';

export default {
  name: 'Snackbar',

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      snackbars: `${SNACKBAR_MODULE}/instances`
    })
  },

  methods: {
    isVisible(snackbar) {
      const { isVisible } = snackbar;
      return isVisible;
    },

    onClose(snackbar) {
      this.$store.commit(`${SNACKBAR_MODULE}/CLOSE`, { instance: snackbar });
    }
  }
};
</script>

<style lang="scss" scoped></style>
