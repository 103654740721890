import { render, staticRenderFns } from "./Snackbar.vue?vue&type=template&id=ede9d79c&scoped=true&"
import script from "./Snackbar.vue?vue&type=script&lang=js&"
export * from "./Snackbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ede9d79c",
  null
  
)

export default component.exports