import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import { VAlert } from 'vuetify/lib';
import TableManualsDocumentTree from '@/components/Table/TableManualsDocumentTree';

export default {
  methods: {
    setTableHeight(view, minTableHeight) {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        return;
      }

      if (!view) {
        return false;
      }

      const viewHeight = view.offsetHeight;
      let totalUsedHeight = 0;
      view.childNodes.forEach(child => {
        if (!child.classList || child.classList.contains('row--table-container') || child.classList.contains('v-navigation-drawer')) {
          return false;
        }
        totalUsedHeight += child.offsetHeight;
      });

      // const tableElement = document.querySelector('.table__ad-overview table');
      // const tableElementHeight = tableElement ? tableElement.offsetHeight : 0;

      const tableHeight = viewHeight - totalUsedHeight;
      this.tableHeight = `${tableHeight - 100}px`;
      // if (tableElementHeight && tableElementHeight < tableHeight - 110) {
      //   this.tableHeight = `${tableElementHeight}px`;
      // }

      document.querySelector('#app').style.height = '100vh';
      if (window.outerHeight < minTableHeight) {
        document.querySelector('#app').style.height = 'auto';
        this.tableHeight = `auto`;
      }

      // console.log(totalUsedHeight, this.tableHeight, tableHeight, viewHeight, view, window.outerHeight);
    },

    toggleExpandRow(item, index, component = 'c') {
      const parentRow = this.$refs[`row-${index}`];

      if (!parentRow || !parentRow.length) {
        return;
      }

      let isCollapsed = true;
      const nextSibling = parentRow[0].nextSibling;
      if (nextSibling && nextSibling.getAttribute('data-ref') === `row-${index}`) {
        isCollapsed = false;
      }

      // Toggle button state
      const btn = this.$refs[`btn-collapse-${index}`];
      if (btn && btn.length) {
        btn[0].$el.classList.toggle('is-active');
      }

      // Remove the expanded row
      if (!isCollapsed) {
        nextSibling.remove();
        this.$emit('resize');
        return;
      }

      // Create the table row element and the html template
      const tr = document.createElement('tr');
      // Add the attribute so we can identify the element later on to mount the row
      tr.setAttribute('data-ref', `row-${index}`);
      // Create new vue instance for table row
      const expandableRowInstance = new Vue({
        vuetify,
        components: {
          TableManualsDocumentTree,
          VAlert
        },
        i18n: this.$i18n,
        data() {
          return {
            index,
            item: Object.assign({}, item)
          };
        },
        template: `
          <tr class="table-row__expanded-content" data-ref="row-${index}">
            <th colspan="8" class="pa-2">
              <v-alert text dense type="info" class="mt-4" v-if="!item.children.length">
                {{ $t('hint.noData') }}
              </v-alert>

              <div v-else>
                <component :is="'${component}'" :items="item.children" />
              </div>
            </th>
          </tr>
        `
      });

      if (parentRow && parentRow.length) {
        parentRow[0].after(tr);
        expandableRowInstance.$mount(`[data-ref="row-${index}"]`);
      }

      this.$emit('resize');
    }
  }
};
