import client from '@/config/directus.api';

const COLLECTION = 'airports';
const FIELDS = ['*'];

/**
 * Get items
 */
export function getIcaos(limit = -1, search = null) {
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: FIELDS,
      filter: {
        status: {
          _eq: 'published'
        }
      },
      sort: 'apt_name',
      limit,
      search
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Get item
 */
export function getIcao(id) {
  return client
    .items(COLLECTION)
    .readOne(id, {
      fields: FIELDS
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
