import * as Flightlog from '@/services/customerAircraftFlightlogs';

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    all: state => state.all,
    isLoading: state => state.isLoading,
    latest: state => {
      let result = {};
      const all = state.all.slice();
      if (all.length) {
        const sorted = all.sort((a, b) => a.number - b.number);

        sorted.reverse();
        result = sorted[0];
      }
      return result;
    }
  },

  mutations: {
    SET_FLIGHTLOG(state, payload) {
      state.all.push(payload);
    },

    SET_FLIGHTLOGS(state, payload) {
      state.all = payload.slice();
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },

  actions: {
    async GET_FLIGHTLOGS({ commit }, payload = {}) {
      try {
        commit('SET_LOADING', true);

        const response = await Flightlog.getFlightlogs(payload);
        const { data } = response;
        if (data) {
          commit('SET_FLIGHTLOGS', data);
        }
      } catch (error) {
        return error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async CREATE({ commit }, payload) {
      try {
        commit('SET_LOADING', true);

        const response = await Flightlog.createFlightlog(payload);
        const { data } = response;
        if (data) {
          commit('SET_FLIGHTLOG', data);
        }
        return response;
      } catch (error) {
        return error;
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'customerAircraftFlightlogs';
