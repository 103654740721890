import { remove } from 'lodash';
import { guid } from '@/services/utils';

class Snackbar {
  constructor(obj) {
    this.actionBtnName = obj.actionBtnName || null;
    this.text = obj.text;
    this.id = obj.id;
    this.isVisible = obj.isVisible;
    this.onClickActionBtn = obj.onClickActionBtn || null;
    this.showClose = obj.showClose || true;
    this.timeout = obj.timeout;
    this.title = obj.title;
    this.top = obj.top;
    this.color = obj.color;
  }
}

export default {
  namespaced: true,

  state: {
    default: {
      actionBtnName: null,
      text: '',
      id: null,
      isVisible: true,
      onClickActionBtn: null,
      showClose: true,
      timeout: 0,
      title: '',
      top: true,
      color: 'grey darken-4'
    },
    instances: []
  },

  getters: {
    instances: state => state.instances
  },

  mutations: {
    ADD(state, payload) {
      payload.id = guid();
      state.instances.push(payload);
    },

    CLOSE(state, payload) {
      // If the instance has an id remove dialog with this id
      // othwise remove the last item in array
      if (payload && payload.instance) {
        remove(state.instances, item => {
          if (item.id === payload.instance.id) {
            item.isVisible = false;
          }
          return item.id === payload.instance.id;
        });
      } else {
        state.instances.pop();
      }
    }
  },

  actions: {
    OPEN({ commit, state }, payload) {
      const currPayload = Object.assign({}, state.default, payload);

      // Create new instance and add to instances array
      const snackbar = new Snackbar(currPayload);
      commit('ADD', snackbar);

      // Remove items after timeout
      if (snackbar.timeout) {
        setTimeout(() => {
          commit('CLOSE', snackbar);
        }, snackbar.timeout);
      }
    }
  }
};

export const MODULE_NAME = 'snackbar';
