<template>
  <div>
    <v-select
      v-model="selectedCustomerAircraft"
      :items="customerAircrafts"
      :loading="isLoadingCustomerAircrafts"
      :item-text="getItemText"
      item-value="id"
      menu-props="auto"
      :label="$t('label.selectAircraft')"
      :placeholder="$t('label.selectAircraft')"
      hide-details
      prepend-icon="mdi-airplane"
      single-line
      flat
      @change="onChange"
    ></v-select>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';

export default {
  name: 'SelectCustomerAircraft',

  computed: {
    ...mapGetters({
      customerAircrafts: `${CUSTOMER_AIRCRAFTS_MODULE}/all`,
      isLoadingCustomerAircrafts: `${CUSTOMER_AIRCRAFTS_MODULE}/isLoading`
    }),

    ...mapState({
      selectedCustomerAircraftId: state => state[`${CUSTOMER_AIRCRAFTS_MODULE}`].selectedId
    })
  },

  data() {
    return {
      selectedCustomerAircraft: null
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.selectedCustomerAircraft = this.selectedCustomerAircraftId;
    },

    getItemText(item) {
      const variant = get(item, 'aircraft.easa_types.variant');
      let result = `${item.registration}`;
      if (variant) {
        result = `${item.registration}  (${variant})`;
      }
      return result;
    },

    onChange(value) {
      this.$store.commit(`${CUSTOMER_AIRCRAFTS_MODULE}/SET_SELECTED`, value);
      this.$emit('change', value);
    }
  },

  watch: {
    selectedCustomerAircraftId() {
      this.init();
    }
  }
};
</script>

<style lang="scss" scoped></style>
