import * as Country from '@/services/country';
import { transformCountriesForSelect } from '@/shared/transformers';

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    countriesForSelect: state => {
      // Return default value if no country is available
      if (!state.all.length) {
        return [
          {
            label: 'Deutschland',
            value: 1
          }
        ];
      }

      return transformCountriesForSelect(state.all);
    },

    isLoading: state => state.isLoading
  },

  mutations: {
    SET_COUNTRIES(state, payload) {
      state.all = payload;
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },

  actions: {
    async GET_COUNTRIES({ commit }) {
      try {
        commit('SET_LOADING', true);
        const response = await Country.getCountries();
        const { data = [] } = response;
        commit('SET_COUNTRIES', data);
        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'countries';
