<template>
  <v-list dense>
    <v-list-item link :to="{ name: SETTINGS_USER }">
      <v-list-item-icon class="mr-4">
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ $t('nav.MyProfile') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-icon class="mr-4">
        <v-icon>mdi-cog</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ $t('nav.Settings') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="{ name: SETTINGS_SWITCH_ROLE }">
      <v-list-item-icon class="mr-4">
        <v-icon>mdi-account-convert-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ $t('nav.switchRole') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list-item link :to="{ name: LOGOUT }">
      <v-list-item-icon class="mr-4">
        <v-icon>mdi-logout</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ $t('nav.Logout') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import { LOGOUT, SETTINGS_USER, SETTINGS_SWITCH_ROLE } from '@/router/route-types';

export default {
  name: 'ListBarMenu',

  computed: {
    ...mapGetters({
      company: `${USER_MODULE}/company`,
      user: `${USER_MODULE}/currentUser`
    }),

    title() {
      return process.env.VUE_APP_TITLE;
    },

    userInitials() {
      let result = '';
      const first_name = get(this.user, 'first_name');
      const last_name = get(this.user, 'last_name');

      if (first_name && last_name) {
        result = `${first_name.charAt(0)}${last_name.charAt(0)}`;
      }
      return result;
    },

    LOGOUT() {
      return LOGOUT;
    },

    SETTINGS_USER() {
      return SETTINGS_USER;
    },

    SETTINGS_SWITCH_ROLE() {
      return SETTINGS_SWITCH_ROLE;
    }
  }
};
</script>

<style lang="scss" scoped></style>
