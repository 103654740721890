import AppLayout from '@/layouts/AppLayout.vue';

import {
  DOCUMENT
  // DOCUMENT_REGISTRATION_FORM,
  // DOCUMENT_ARC,
  // DOCUMENT_IHP,
  // DOCUMENT_INSURANCE_POLICY,
  // DOCUMENT_AIRWORTHINESS_CERTIFICATION,
  // DOCUMENT_AIRWORTHINESS_REVIEW
} from '@/router/route-types';

export default {
  path: '/documents',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: DOCUMENT,
      component: () => import(/* webpackChunkName: "documents" */ '@/views/Documents/DocumentsIndex.vue'),
      meta: {
        auth: true,
        title: 'Documents'
      }
    }
    // {
    //   path: 'arc',
    //   name: DOCUMENT_ARC,
    //   redirect: {
    //     name: DOCUMENT,
    //     query: {
    //       docType: 'airworthiness-inspection'
    //     }
    //   }
    // },
    // {
    //   path: 'ihp',
    //   name: DOCUMENT_IHP,
    //   redirect: {
    //     name: DOCUMENT,
    //     query: {
    //       docType: 'aircraft-maintenance-program'
    //     }
    //   }
    // },
    // {
    //   path: 'airworthiness-certification',
    //   name: DOCUMENT_AIRWORTHINESS_CERTIFICATION,
    //   redirect: {
    //     name: DOCUMENT,
    //     query: {
    //       docType: 'certificate-of-airworthiness'
    //     }
    //   }
    // },
    // {
    //   path: 'insurance-policy',
    //   name: DOCUMENT_INSURANCE_POLICY,
    //   redirect: {
    //     name: DOCUMENT,
    //     query: {
    //       docType: 'insurance-certificate'
    //     }
    //   }
    // },
    // {
    //   path: 'airworthiness-review',
    //   name: DOCUMENT_AIRWORTHINESS_REVIEW,
    //   redirect: {
    //     name: DOCUMENT,
    //     query: {
    //       docType: 'airworthiness-review-certificate'
    //     }
    //   }
    // },
    // {
    //   path: 'registration-form',
    //   name: DOCUMENT_REGISTRATION_FORM,
    //   component: () => import(/* webpackChunkName: "documents" */ '@/views/Documents/DocumentsIndex.vue'),
    //   meta: {
    //     auth: true,
    //     title: 'Documents'
    //   }
    // }
  ]
};
