import { RESET_PASSWORD } from '@/router/route-types';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import ResetPassword from '@/views/ResetPassword.vue';

export default {
  path: '/reset-password',
  component: DefaultLayout,
  meta: {
    auth: false
  },
  children: [
    {
      path: '',
      name: RESET_PASSWORD,
      component: ResetPassword,
      meta: {
        auth: false
      }
    }
  ]
};
