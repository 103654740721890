<template>
  <v-app light>
    <router-view />
  </v-app>
</template>

<script>
import * as Browser from '@/services/browser';
import { MODULE_NAME as TRANSLATIONS_MODULE } from '@/store/modules/translations';

export default {
  name: 'App',

  computed: {
    translations() {
      return this.$store.state.translations.all;
    }
  },

  mounted() {
    this.handleLanguage();
  },

  methods: {
    async handleLanguage() {
      const language = Browser.getLanguage();
      let lang = language.toLowerCase();

      if (this.user && this.user.locale) {
        lang = this.user.locale.slice(0, 2);
      }

      // Set default translation from local file
      this.$store.commit(`${TRANSLATIONS_MODULE}/SET_TRANSLATION`);

      const translations = find(this.translations, { id: lang });
      if (translations) {
        this.$store.commit(`${TRANSLATIONS_MODULE}/SET_TRANSLATION`, {
          locale: lang,
          message: translations.messages
        });
      } else {
        this.$store.dispatch(`${TRANSLATIONS_MODULE}/GET_TRANSLATION`, lang);
      }
    }
  }
};
</script>

<style lang="scss">
.v-application {
  height: 100vh;
}
.v-application--wrap {
  min-height: unset !important;
}
</style>
