<template>
  <div class="view">
    <div class="view__inner view__inner--shadow">
      <v-alert type="success" text class="mt-2">
        {{ $t('text.LogoutSuccess') }}
      </v-alert>

      <div class="mt-2">
        <v-btn :to="{ name: LOGIN }" color="primary" depressed block>{{ $t('text.loginAgain') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN, LOGOUT } from '@/router/route-types';

export default {
  name: 'Logout',

  computed: {
    LOGIN() {
      return LOGIN;
    }
  },

  mounted() {
    if (this.$route.query.success !== '1') {
      this.$store.dispatch('auth/LOGOUT').then(() => {
        this.$router.push({ name: LOGOUT, query: { success: 1 } });
        window.location.reload();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.view {
  flex: 1;
  height: 100%;

  &__inner {
    background-color: #ffffff;
    max-width: 24rem;
    padding: 2rem;
    // background-color: #ffffff;
    // box-shadow: 0 0 10px rgba(17, 17, 17, 0.1);

    @media screen and (min-width: $screen-md) {
      max-width: 30rem;
    }
  }
}

.logo {
  max-height: 4rem;
  width: auto;

  @media screen and (min-width: $screen-md) {
    max-height: 5rem;
  }
}
</style>
