/* eslint-disable no-prototype-builtins */
import { get } from 'lodash';

export const availableLangCodes = ['de-DE', 'fr-FR'];

/**
 * Source: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
export function guid() {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

/**
 * Convert image to base 64
 * @param {File} file
 */
export function convertImageToBase64(file) {
  const reader = new FileReader();
  const { raw } = file;

  const p = new Promise(resolve => {
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
  });

  reader.readAsDataURL(raw);
  return p;
}

/**
 * Get asset url of image
 *
 * @param   {String}  fileId
 *
 * @return  {String}
 */
export function getAssetUrl(fileId) {
  return `${process.env.VUE_APP_API_TARGET}/assets/${fileId}`;
}

/**
 * Get image url
 *
 * @param   {String}  url
 *
 * @return  {String}
 */
export function getImageUrl(url) {
  return `${process.env.VUE_APP_API_TARGET}/${url}`;
}

export function getTranslatedProperties(propName, langCode, items) {
  const result = [];
  items.forEach(item => {
    const { translations = [] } = item;
    if (!translations.length) {
      result.push(Object.assign({}, item));
      return;
    }

    const name = getTranslatedProperty(propName, langCode, translations);
    if (name) {
      item.name = name;
    }

    result.push(Object.assign({}, item));
  });

  return result;
}

/**
 * Get translated property of directus collection item
 *
 * @param   {string}  propName      name
 * @param   {string}  langCode      "de-DE"
 * @param   {Array}  translations  Translations object from directus
 *
 * @return  {String}
 */
export function getTranslatedProperty(propName, langCode, translations) {
  let result = null;
  const item = translations.find(translation => {
    return get(translation, `languages_code`) === langCode;
  });
  console.log(item);

  if (get(item, `${propName}`)) {
    result = item[propName];
  }
  return result;
}

/**
 * Get translated values for which an translateable property exist
 *
 * @param   {Object}  item
 * @param   {String}  language
 *
 * @return  {Object}
 */
export function getTranslationForItem(item, language = 'de-DE') {
  const { translations } = item;
  const keys = [];
  const excludeKeys = ['id', 'translations'];

  if (!translations) {
    return item;
  }

  // Create array of keys for which proberly an translation exist
  Object.keys(item).forEach(key => {
    if (!excludeKeys.includes(key)) {
      keys.push(key);
    }
  });

  const foundTranslation = translations.find(translation => {
    return translation.languages_code === language;
  });

  if (foundTranslation) {
    keys.forEach(key => {
      if (item[key] && foundTranslation[key]) {
        item[key] = foundTranslation[key];
      }
    });
  }

  return item;
}

/**
 * Get relational objects for given property
 *
 * @param   {object}  item
 * @param   {string}  prop
 * @param   {string}  propRelation
 *
 * @return  {array}
 */
export function getRelationNames(item, prop, propRelation, translated = false, language = 'de-DE') {
  const result = [];
  const relations = get(item, prop, []);
  if (relations.length) {
    relations.forEach(relation => {
      const itemRelation = get(relation, propRelation);
      if (itemRelation) {
        if (translated) {
          const translatedItem = getTranslationForItem(itemRelation, language);
          result.push(translatedItem);
        } else {
          result.push(itemRelation);
        }
      }
    });
  }
  return result;
}

/**
 * Get icon by file type
 *
 * @param   {String}  type
 *
 * @return  {String}
 */
export function getIconByType(type) {
  let result = 'mdi-file-outline';
  if (type.includes('pdf')) {
    result = 'mdi-file-pdf-box';
  } else if (type.includes('msword')) {
    result = 'mdi-file-word-outline';
  } else if (type.includes('msexcel')) {
    result = 'mdi-file-excel-outline';
  }
  return result;
}

export function getSortBtnClass(props, currentSort) {
  let result = 'btn--disabled';
  const prop = get(currentSort, 'prop', '');
  if (props.includes(prop)) {
    result = '';
  }

  return result;
}

export function getSortIconClass(props, currentSort) {
  let result = '';
  const prop = get(currentSort, 'prop', '');
  const sortOrder = get(currentSort, 'value', '');
  if (props.includes(prop)) {
    result = '';
    if (sortOrder === 'desc') {
      result = 'is-down';
    } else if (sortOrder === 'asc') {
      result = 'is-up';
    }
  }

  return result;
}
