import Vue from 'vue';

import VueI18n from 'vue-i18n';
import messages from './messages';

Vue.use(VueI18n);

const dateTimeFormats = {
  en: {
    short: {
      year: '2-digit',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    my: {
      year: '2-digit',
      month: 'short'
    }
  },
  de: {
    short: {
      year: '2-digit',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    my: {
      year: '2-digit',
      month: 'short'
    }
  }
};

export default new VueI18n({
  dateTimeFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages
});
