<template>
  <div class="view view--light">
    <h2 class="m-t-0 c-black">
      <span>{{ $t('Settings') }}</span>
    </h2>
    <v-card tile class="mt-4">
      <v-tabs>
        <v-tab v-for="(item, index) in items" :key="index" link :to="item.route">{{ $t(item.name) }}</v-tab>
      </v-tabs>

      <div>
        <!-- Main content goes here -->
        <router-view class="pa-3"></router-view>
      </div>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import { SETTINGS_SWITCH_ROLE, SETTINGS_USER, SETTINGS_CHANGE_PASSWORD } from '@/router/route-types';

export default {
  name: 'Settings',

  computed: {
    ...mapGetters({
      user: `${USER_MODULE}/currentUser`
    }),

    isCurrentUserAdmin() {
      const { is_company_admin } = this.user;
      return is_company_admin;
    },

    items() {
      const result = [
        {
          name: 'nav.MyProfile',
          route: {
            name: SETTINGS_USER
          },
          onlyAdmin: false
        },
        {
          name: 'nav.ChangePassword',
          route: {
            name: SETTINGS_CHANGE_PASSWORD
          },
          onlyAdmin: false
        },
        {
          name: 'nav.switchRole',
          route: {
            name: SETTINGS_SWITCH_ROLE
          },
          onlyAdmin: false
        }
      ];

      // Filter items to be shown for either admins or users
      return result.filter(item => {
        if (!this.isCurrentUserAdmin) {
          return !item.onlyAdmin;
        } else {
          return true;
        }
      });
    }
  },

  methods: {
    itemIsInPath(item) {
      const routeName = get(item, 'route.name', '');
      return routeName === this.$route.name;
    }
  }
};
</script>

<style lang="scss" scoped></style>
