export function transformForSelect(
  items,
  params = {
    label: 'title',
    value: 'id'
  }
) {
  items.forEach(item => {
    item.label = item[params.label];
    item.value = item[params.value];
  });

  return items;
}

export function transformForTree(items) {
  items.forEach(item => {
    item.label = item.name;

    if (item.children) {
      item.children = transformForTree(item.children);
    }
  });

  return items;
}

export function transformUsersForSelect(items) {
  items.forEach(item => {
    item.label = `${item.last_name}, ${item.first_name}`;
    item.value = item.id;
  });

  return items;
}

export function transformCountriesForSelect(items) {
  items.forEach(item => {
    item.label = item.name;
    item.value = item.id;
  });

  return items;
}

export function transformTranslations(items) {
  const result = {};

  items.forEach(item => {
    const entries = Object.entries(item);
    result[entries[1][1]] = entries[0][1];
  });

  return result;
}
