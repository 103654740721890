import * as Icao from '@/services/icao';
// import { transformForSelect } from '@/shared/transformers';

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    isLoading: state => state.isLoading,

    all: state => {
      return state.all;
      // return transformForSelect(state.all, { label: 'name', value: 'id' });
    }
  },

  mutations: {
    SET_ICAOS(state, payload) {
      state.all = payload;
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },

  actions: {
    async GET_ICAOS({ commit }) {
      commit('SET_LOADING', true);
      try {
        const response = await Icao.getIcaos();
        const { data = [] } = response;
        commit('SET_ICAOS', data);
        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'icao';
