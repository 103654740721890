import { MANUALS } from '@/router/route-types';
import AppLayout from '@/layouts/AppLayout.vue';
import ManualsIndex from '@/views/Manuals/ManualsIndex.vue';

export default {
  path: '/manuals',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: MANUALS,
      component: ManualsIndex,
      meta: {
        auth: true,
        title: 'Handbücher'
      }
    }
  ]
};
