import * as TagService from '@/services/baseTags';
import { getTranslatedProperties } from '@/services/utils';

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    all: state => state.all,

    allTranslated: state => langCode => {
      if (!langCode) {
        return state.all;
      }
      const result = getTranslatedProperties('name', langCode, state.all);

      // Source: https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
      return result.sort((a, b) => {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    },

    isLoading: state => state.isLoading,

    tagIdsByAbbrs: state => tagAbbrs => {
      const result = [];
      state.all.forEach(tag => {
        const { abbr } = tag;
        if (abbr && tagAbbrs.includes(abbr.toLowerCase())) {
          result.push(tag.id);
        }
      });
      return result;
    },

    tagIdsByValues: state => tagValues => {
      const result = [];
      state.all.forEach(tag => {
        const { value } = tag;
        if (value && tagValues.includes(value.toLowerCase())) {
          result.push(tag.id);
        }
      });
      return result;
    }
  },

  mutations: {
    SET_TAGS(state, payload) {
      state.all = payload;
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },

  actions: {
    async GET_TAGS({ commit }, payload) {
      try {
        commit('SET_LOADING', true);
        const response = await TagService.getTags(payload);
        const { data } = response;
        commit('SET_TAGS', data);
        return response;
      } catch (error) {
        throw new Error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'tags';
