<template>
  <div ref="view" v-resize="onResize" class="view view--light">
    <v-row class="mt-1" v-if="customerAircraft">
      <v-col :cols="12" class="text-right">
        <v-btn color="primary" text @click="toggleAside({ isClipped: true, isTemporary: false })">
          <v-icon>mdi-filter-outline</v-icon> <span class="ml-3">{{ $t('toggleFilter') }}</span></v-btn
        >
      </v-col>
    </v-row>

    <v-card elevation="1" class="mt-3 row--table-container" v-if="customerAircraft">
      <v-row>
        <v-col :cols="12" class="py-0">
          <v-skeleton-loader v-if="isLoading" type="table-thead,table-tbody"></v-skeleton-loader>
          <TableManuals
            v-else
            :items="filteredFiles"
            :height="tableHeight"
            :columns="filters.columns.length ? filters.columns : tableColumns"
            class="table__docs-overview"
            @resize="onResize"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row v-else class="mt-4">
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>

    <!-- Aside -->
    <v-navigation-drawer
      v-model="aside.isVisible"
      app
      :clipped="aside.isClipped"
      :temporary="aside.isTemporary"
      right
      :width="navDrawerWidth"
      :class="{ 'v-navigation-drawer--open': aside.isVisible }"
    >
      <div class="d-flex align-center justify-space-between py-2 pr-2 pl-4 bg-color-lighter-gray">
        <h3 class="body-1">{{ aside.headline }}</h3>
        <v-btn icon depressed text @click="aside.isVisible = false"> <v-icon>mdi-close</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
      <FilterBarManuals
        :filter-options="filterOptions"
        :protected-selected-tag-values="[manualTagValues[0]]"
        :table-columns="tableColumns"
        :selected-filter-options="compFilters"
        :selected-columns="filters.columns.length ? filters.columns : tableColumns"
        class="mt-3 px-3 is-inside-nav-drawer"
        @change="onFilterChange"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import * as FileService from '@/services/file';
import tableMixin from '@/shared/mixins/table';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import FilterBarManuals from '@/components/FilterBar/FilterBarManuals';
import TableManuals from '@/components/Table/TableManuals';

export default {
  name: 'ManualsIndex',

  mixins: [tableMixin],

  components: {
    AlertNoAircraft,
    FilterBarManuals,
    TableManuals
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      currentUser: `${USER_MODULE}/currentUser`,
      tags: `${TAGS_MODULE}/all`
    }),

    compFilters() {
      const filters = JSON.parse(JSON.stringify(this.filters));
      const manualTagValue = 'manual';
      const foundTagValue = filters.tags.find(tag => tag === manualTagValue);
      if (!foundTagValue) {
        filters.tags.push(manualTagValue);
      }
      return filters;
    },

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    customerAircraftEasaType() {
      return get(this.customerAircraft, 'aircraft.easa_types', { name: '' });
    },

    filterOptions() {
      const result = {
        tags: [...this.tags]
      };
      return result;
    },

    filteredFiles() {
      let result = this.files.slice();
      const { query = '' } = this.filters;

      // search query for props: [title, description]
      if (query && query.length) {
        result = result.filter(file => {
          const title = file.title.toLowerCase();
          const description = file.description ? file.description.toLowerCase() : '';

          return title.toLowerCase().includes(query.toLowerCase()) || description.toLowerCase().includes(query.toLowerCase());
        });
      }

      return result;
    },

    navDrawerWidth() {
      let result = '80vw';

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = '30vw';
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        result = '25vw';
      }

      return result;
    },

    manualTagValues() {
      return ['manual', 'aircraft-maintenance-manual'];
    }
  },

  data() {
    return {
      aside: {
        component: '',
        headline: this.$t('Filters'),
        isClipped: false,
        isTemporary: true,
        isVisible: false
      },
      files: [],
      filters: {
        columns: [],
        query: '',
        tags: []
      },
      isLoading: false,
      tableColumns: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Revision',
          value: 'revision'
        },
        {
          text: 'Company',
          value: 'company'
        },
        {
          text: 'Authorities',
          value: 'authorities'
        },
        {
          text: 'Tags',
          value: 'tags'
        },
        {
          text: 'MaintenanceTypes',
          value: 'maintenanceTypes'
        }
      ],
      tableHeight: ''
    };
  },

  watch: {
    $route() {
      this.handleRouteChange();
    },

    customerAircraftId() {
      this.requestFiles();
    }
  },

  mounted() {
    // Pre-fill filters
    this.filters.columns.push(this.tableColumns[0]);
    this.filters.columns.push(this.tableColumns[1]);
    this.filters.columns.push(this.tableColumns[2]);

    this.handleRouteChange(this.$route.to, this.$route.from);

    this.init();
  },

  methods: {
    handleRouteChange() {
      const { tags } = this.$route.query;

      const queryTags = [];
      if (tags && typeof tags === 'string') {
        queryTags.push(tags);
      } else if (tags) {
        queryTags.push(...tags);
      }
      this.filters.tags = queryTags.slice();
      this.onFilterChange(this.filters);
    },

    init() {
      if (this.customerAircraftId) {
        this.requestFiles();
      }
    },

    onFilterChange(values) {
      this.filters = Object.assign({}, values);
      this.init();
    },

    async onResize() {
      await this.$nextTick(() => {
        this.setTableHeight(this.$refs['view'], 550, document.querySelector('.table__docs-overview table'));
      });
    },

    async requestFiles() {
      let manualTagValues = this.manualTagValues.slice();
      if (this.compFilters.tags.length) {
        manualTagValues = this.compFilters.tags.slice();
      }

      try {
        this.isLoading = true;
        const { data = [] } = await FileService.getManuals({
          customerAircraftId: this.customerAircraftId,
          tags: manualTagValues
        });
        this.files = FileService.transformRootLeafsTwoLevel(data.slice());

        setTimeout(() => {
          this.onResize();
        }, 100);
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    toggleAside(params = {}) {
      // reset aside settings
      const { component = '', isClipped = false, isTemporary = true } = params;
      this.aside.component = component;
      this.aside.headline = this.$t('Filters');
      this.aside.isClipped = isClipped;
      this.aside.isTemporary = isTemporary;

      if (component === 'tableColumns') {
        this.aside.component = component;
        this.aside.isClipped = true;
        this.aside.isTemporary = false;
        this.aside.headline = this.$t('selectColumns');
      }

      this.aside.isVisible = !this.aside.isVisible;
    }
  }
};
</script>

<style lang="scss" scoped>
.view {
  overflow: auto;
}
</style>
