import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import client from '@/config/directus.api';
import { clearUserData, logout } from '@/services/auth';
import { get } from 'lodash';
import { LOGIN } from '@/router/route-types';

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  linkActiveClass: 'is-active'
});

/**
 * Process for each route to check for authentification and redirection
 * @param  {Object} to
 * @param  {Object} from
 * @param  {Function} next
 * @return {void}
 */
router.beforeEach(async (to, from, next) => {
  // let isAuthenticated = false;
  // const authToken = localStorage.getItem('auth_token');
  // const authExpires = localStorage.getItem('auth_expires');
  // const authExpiresAt = localStorage.getItem('auth_expires_at');

  // if (authToken && authToken.length && authExpires && authExpiresAt) {
  //   isAuthenticated = true;
  // }

  // // Check protected routes
  const isRouteProtected = get(to, 'meta.auth', false);
  if (isRouteProtected) {
    // Refresh token, on error redirect to login page
    try {
      await client.auth.refresh();
    } catch (error) {
      clearUserData();
      logout();
      next({ name: LOGIN });
      return;
    }

    // Redirect user to login page if not authenticated
    // if (!isAuthenticated) {
    //   clearUserData();
    //   logout();
    //   next({ name: LOGIN });
    //   return;
    // }
  }

  next();
});

/**
 * Process for each route after entering
 * @param  {Object} to
 * @param  {Object} from
 * @return {void}
 */
// router.afterEach((to, from) => {
// store.commit('SET_SIDEBAR', { visible: false });
// });

export default router;
