import Vue from 'vue';
import Vuex from 'vuex';

import actions from '@/store/actions';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import state from '@/store/state';

// Modules
// import aside from '@/store/modules/aside';
import auth from '@/store/modules/auth';
import customerAircrafts from '@/store/modules/customerAircrafts';
import customerAircraftFlightlogs from '@/store/modules/customerAircraftFlightlogs';
// import companies from '@/store/modules/companies';
import countries from '@/store/modules/countries';
import icao from '@/store/modules/icao';
// import dialog from '@/store/modules/dialog';
import menu from '@/store/modules/menu';
import snackbar from '@/store/modules/snackbar';
import tags from '@/store/modules/tags';
import translations from '@/store/modules/translations';
import user from '@/store/modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    // aside,
    auth,
    customerAircrafts,
    customerAircraftFlightlogs,
    countries,
    // dialog,
    icao,
    menu,
    snackbar,
    tags,
    translations,
    user
  }
});
