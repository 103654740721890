import client from '@/config/directus.api';

const COLLECTION = 'base_tags';

/**
 * Get tags
 */
export async function getTags(filter = {}) {
  return client
    .items(COLLECTION)
    .readByQuery({
      filter,
      fields: ['*', 'translations.*'],
      limit: -1,
      sort: 'name'
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
