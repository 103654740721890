import * as CustomerAircrafts from '@/services/customerAircraft';

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false,
    selectedId: null
  },

  getters: {
    all: state => state.all,
    isLoading: state => state.isLoading,
    selected: state => state.all.find(item => item.id === state.selectedId)
  },

  mutations: {
    SET_AIRCRAFTS(state, payload) {
      state.all = payload.slice();
    },

    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_SELECTED(state, id) {
      state.selectedId = id;
    }
  },

  actions: {
    async GET_AIRCRAFTS({ commit }) {
      try {
        commit('SET_LOADING', true);

        const response = await CustomerAircrafts.getAircrafts();
        const { data } = response;
        if (data) {
          commit('SET_AIRCRAFTS', data);
        }
      } catch (error) {
        return error;
      } finally {
        commit('SET_LOADING', false);
      }
    }
  }
};

export const MODULE_NAME = 'customerAircrafts';
