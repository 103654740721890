import { LOGIN } from '@/router/route-types';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import Login from '@/views/Login.vue';

export default {
  path: '/login',
  component: DefaultLayout,
  meta: {
    auth: false
  },
  children: [
    {
      path: '',
      name: LOGIN,
      component: Login,
      meta: {
        auth: false
      }
    }
  ]
};
