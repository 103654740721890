<template>
  <div>
    <v-alert v-model="alert.isVisible" top :type="alert.type" text>
      <span v-html="alert.message"></span>

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="alert.isVisible = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-alert>

    <v-form ref="form" @submit.prevent="onSubmit" v-if="!alert.isVisible">
      <v-text-field
        clearable
        :label="$t('EMail')"
        type="email"
        v-model="form.email"
        :error-messages="emailErrors"
        @blur="$v.form.email.$touch()"
        required
      >
      </v-text-field>

      <v-btn color="primary" class="mt-2 w-100" type="submit" depressed :loading="isLoading">{{ $t('ResetPassword') }}</v-btn>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { get } from 'lodash';
import { MODULE_NAME as AUTH_MODULE } from '@/store/modules/auth';

export default {
  name: 'FormResetPassword',

  mixins: [validationMixin],

  validations: {
    form: {
      email: { required, email }
    }
  },

  props: {
    username: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) {
        return errors;
      }
      if (!this.$v.form.email.email) {
        errors.push(this.$t('text.WrongFormat'));
      }
      if (!this.$v.form.email.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    }
  },

  data() {
    return {
      alert: {
        isVisible: false,
        message: '',
        type: 'info'
      },
      form: {
        email: ''
      },
      isLoading: false
    };
  },

  mounted() {
    this.form.email = this.username;
  },

  methods: {
    onSubmit() {
      this.$v.$touch();
      console.log('submit form');

      if (this.$v.$invalid) {
        // Show validation errors
        return false;
      } else {
        this.resetPassword();
      }
    },

    async resetPassword() {
      this.isLoading = true;

      const result = await this.$store.dispatch(`${AUTH_MODULE}/RESET_PASSWORD`, this.form.email);
      const code = get(result, 'code');
      const message = get(result, 'message');
      const isPublic = get(result, 'public');

      this.isLoading = false;

      // Incorrect authentification
      if (code && message) {
        this.alert.message = message;
        this.alert.isVisible = true;
        this.alert.type = 'error';
        return;
      }

      // Show success message
      if (isPublic) {
        this.alert.type = 'success';
        this.alert.message = this.$t('text.PasswordResetSuccess');
        this.alert.isVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
