import AppLayout from '@/layouts/AppLayout.vue';

import { OPERATINGHOURS } from '@/router/route-types';

export default {
  path: '/operatinghours',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: OPERATINGHOURS,
      component: () => import(/* webpackChunkName: "operatinghours" */ '@/views/Operatinghours/OperatinghoursIndex.vue'),
      meta: {
        auth: true,
        title: 'Operatinghours'
      }
    }
  ]
};
