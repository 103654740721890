<!--
@component:         NotFoundView
@description:       This component show a 404 message to the user.
@authors:           Yannick Herzog
@created:           2019-01-21
@modified:          2020-07
-->
<template>
  <div class="404">
    <div class="404__inner">
      <el-card>
        <div slot="header">
          <span>404</span>
        </div>

        <el-alert
          :title="'Fehler'"
          :closable="false"
          type="error"
          :description="'Entschuldigung, diese Seite gibt es nicht.'"
          show-icon
          class="m-t-0"
        ></el-alert>

        <router-link :to="{ path: '/' }" class="m-t-1 d-block">zur Startseite</router-link>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundView'
};
</script>
