<template>
  <v-form ref="form" @submit.prevent="onSubmit">
    <v-text-field
      :label="$t('EMail')"
      type="email"
      v-model="form.email"
      :error-messages="emailErrors"
      @blur="$v.form.email.$touch()"
      required
    >
    </v-text-field>
    <v-text-field
      :label="$t('Password')"
      v-model="form.password"
      type="password"
      :error-messages="passwordErrors"
      @blur="$v.form.password.$touch()"
      required
    >
    </v-text-field>
    <v-btn color="primary" depressed class="mt-3 w-100" type="submit" :loading="isLoading">{{ $t('Login') }}</v-btn>

    <v-snackbar v-model="snackbar.isVisible" top text :color="snackbar.color">
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" depressed v-bind="attrs" @click="snackbar.isVisible = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { get } from 'lodash';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { DASHBOARD } from '@/router/route-types';
import { login } from '@/services/auth';

export default {
  name: 'FormLogin',

  mixins: [validationMixin],

  validations: {
    form: {
      email: { required, email },
      password: { required }
    }
  },

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) {
        return errors;
      }
      if (!this.$v.form.password.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) {
        return errors;
      }
      if (!this.$v.form.email.email) {
        errors.push(this.$t('text.WrongFormat'));
      }
      if (!this.$v.form.email.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    }
  },

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      isLoading: false,
      snackbar: {
        isVisible: false,
        message: '',
        color: ''
      }
    };
  },

  methods: {
    async signIn() {
      console.log('signIn');

      try {
        this.isLoading = true;
        const result = await login(this.form);
        const accessToken = get(result, 'access_token');

        if (accessToken) {
          // Redirect to main page
          this.$router.push({ name: DASHBOARD });
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.errors[0].message');
        this.snackbar.message = errorMessage || this.$t('Cannot login due to some issues');
        this.snackbar.isVisible = true;
        this.snackbar.color = 'error';
      } finally {
        this.isLoading = false;
      }
    },

    onSubmit() {
      this.$v.$touch();
      console.log('submit form');

      if (this.$v.$invalid) {
        // Show validation errors
      } else {
        // do your submit logic here
        this.signIn();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
