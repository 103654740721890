import AppLayout from '@/layouts/AppLayout.vue';

import { LIFECYCLE } from '@/router/route-types';

export default {
  path: '/lifecycle',
  component: AppLayout,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: LIFECYCLE,
      component: () => import(/* webpackChunkName: "lifecycle" */ '@/views/Lifecycle/LifecycleIndex.vue'),
      meta: {
        auth: true,
        title: 'Lifecycle'
      }
    }
  ]
};
