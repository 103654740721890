import client from '@/config/directus.api';

const COLLECTION = 'customer_aircrafts';

/**
 * Get aircrafts
 */
export async function getAircrafts() {
  return client
    .items(COLLECTION)
    .readByQuery({
      filter: {
        status: {
          _eq: 'published'
        }
      },
      fields: [
        '*',
        'aircraft.*',
        'aircraft.easa_types.*',
        'aircraft.product_type.*',
        'aircraft.manufacturers.*',
        'aircraft.manufacturers.companies_id.*',
        'operator.*',
        'resp_person_airworthiness.*',
        'owner.*',
        'maintainance_company.*',
        'cmp_serialized.*',
        'cmp_serialized.cmp_serialized_id.*',
        'cmp_serialized.cmp_serialized_id.position.*',
        'cmp_serialized.cmp_serialized_id.position.translations.*',
        'cmp_serialized.cmp_serialized_id.tmp.*',
        'cmp_serialized.cmp_serialized_id.tmp.manufacturer.*',
        'cmp_serialized.cmp_serialized_id.tmp.manufacturer.companies_id.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.main_cat.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.main_cat.cmp_main_cat_id.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.main_cat.cmp_main_cat_id.translations.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.sub_cat.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.sub_cat.cmp_sub_cat_id.*',
        'cmp_serialized.cmp_serialized_id.tmp.type.sub_cat.cmp_sub_cat_id.translations.*',
        'cmp_unser.*',
        'cmp_unser.cmp_unserialized_id.*',
        'cmp_unser.cmp_unserialized_id.tmp.*',
        'cmp_unser.cmp_unserialized_id.tmp.manufacturer.*',
        'cmp_unser.cmp_unserialized_id.tmp.manufacturer.companies_id.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.main_cat.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.main_cat.cmp_main_cat_id.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.main_cat.cmp_main_cat_id.translations.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.sub_cat.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.sub_cat.cmp_sub_cat_id.*',
        'cmp_unser.cmp_unserialized_id.tmp.type.sub_cat.cmp_sub_cat_id.translations.*'
      ],
      limit: -1
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

export function getEquipmentListAsPdf(customerAircraftId) {
  return client.transport
    .get(`${COLLECTION}/${customerAircraftId}/equipmentlist`, {
      params: {
        download: true
      }
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
