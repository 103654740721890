import client from '@/config/directus.api';

const COLLECTION = 'customer_aircraft_flightlogs';
const FIELDS = ['*', 'location_ldg.*', 'location_to.*'];

/**
 * Get items
 */
export function getFlightlogs({ filter = {} }) {
  const mergedFilter = Object.assign(
    {
      status: {
        _eq: 'published'
      }
    },
    filter
  );
  return client
    .items(COLLECTION)
    .readByQuery({
      fields: FIELDS,
      filter: mergedFilter,
      sort: ['-number'],
      limit: -1
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Get item
 */
export function getFlightlog(id) {
  return client
    .items(COLLECTION)
    .readOne(id, {
      fields: FIELDS
    })
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}

/**
 * Create item
 *
 * @param   {Object}  payload
 *
 * @return  {Promise}
 */
export function createFlightlog(payload) {
  return client
    .items(COLLECTION)
    .createOne(payload)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });
}
