<template>
  <div>
    <v-form ref="form" @submit.prevent="onSubmit">
      <fieldset class="">
        <legend>Aircraft</legend>
        <v-row>
          <v-col :cols="12" :sm="6">
            <v-text-field
              :label="$t('Registration')"
              v-model="form.registration"
              :error-messages="registrationErrors"
              @blur="$v.form.registration.$touch()"
              @input="$v.form.registration.$touch()"
              required
              readonly
              disabled
            >
            </v-text-field>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-select
              v-model="form.customer_aircraft"
              :items="customerAircrafts"
              :item-text="getAircraftText"
              :label="$t('label.selectAircraft')"
              :placeholder="$t('placeholder.selectAircraft')"
              :error-messages="registrationErrors"
              item-value="id"
              menu-props="auto"
              hide-details
              prepend-icon="mdi-airplane"
              single-line
              flat
              required
              readonly
              disabled
              @change="onSelectAircraft"
            ></v-select>
          </v-col>
        </v-row>
      </fieldset>

      <fieldset>
        <legend>Type</legend>

        <v-row>
          <v-col :cols="12">
            <v-select :items="types" :label="$t('label.type')" v-model="form.type" :item-text="item => $t(item.text)"></v-select>
          </v-col>

          <v-col :cols="12" v-if="this.form.type !== this.types[0].value">
            <v-textarea
              v-model="form.notes"
              outlined
              rows="3"
              :label="$t('label.notes')"
              :error-messages="notesErrors"
              @blur="$v.form.notes.$touch()"
              @input="$v.form.notes.$touch()"
            ></v-textarea>
          </v-col>
        </v-row>
      </fieldset>

      <fieldset class="">
        <legend>Insassen</legend>
        <v-row>
          <v-col :cols="12" :sm="6" :md="4">
            <v-autocomplete
              v-model="form.pilot"
              :items="users"
              :item-text="getUserText"
              :label="$t('label.selectPilot')"
              :placeholder="$t('placeholder.selectPilot')"
              :error-messages="pilotErrors"
              @blur="$v.form.pilot.$touch()"
              @input="$v.form.pilot.$touch()"
              menu-props="auto"
              prepend-icon="mdi-account"
              flat
              required
            ></v-autocomplete>
          </v-col>

          <v-col :cols="12" :sm="6" :md="4">
            <v-combobox
              v-model="form.copilot"
              :items="users"
              :item-text="getUserText"
              :label="$t('label.selectCoPilot')"
              :placeholder="$t('placeholder.selectCoPilot')"
              item-value="id"
              menu-props="auto"
              prepend-icon="mdi-account"
              flat
            ></v-combobox>
          </v-col>

          <v-col :cols="12" :sm="6" :md="4">
            <v-text-field
              v-model.number="form.cnt_guests"
              :label="$t('label.cnt_guests')"
              :placeholder="$t('placeholder.cnt_guests')"
              :error-messages="cnt_guestsErrors"
              @blur="$v.form.cnt_guests.$touch()"
              @input="$v.form.cnt_guests.$touch()"
              prepend-icon="mdi-account-supervisor"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </fieldset>

      <fieldset class="">
        <legend>Strecke</legend>
        <v-row>
          <v-col :cols="12" :sm="6">
            <v-autocomplete
              v-model="form.location_to"
              :items="airports"
              :item-text="getIcaoText"
              :search-input.sync="searchAirportTo"
              :label="$t('label.fromIcao')"
              :placeholder="$t('placeholder.fromIcao')"
              :error-messages="location_toErrors"
              :loading="isLoadingAirports"
              @blur="$v.form.location_to.$touch()"
              @input="
                value => {
                  $v.form.location_to.$touch();
                  if (value && !form.location_ldg) {
                    form.location_ldg = value;
                  }
                }
              "
              cache-items
              item-value="id"
              menu-props="auto"
              hide-no-data
              hide-details
              prepend-icon="mdi-map-marker"
              flat
              required
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-autocomplete
              v-model="form.location_ldg"
              :items="airports"
              :item-text="getIcaoText"
              :search-input.sync="searchAirportLdg"
              :label="$t('label.toIcao')"
              :placeholder="$t('placeholder.toIcao')"
              :error-messages="location_ldgErrors"
              :loading="isLoadingAirports"
              @blur="$v.form.location_ldg.$touch()"
              @input="$v.form.location_ldg.$touch()"
              cache-items
              item-value="id"
              menu-props="auto"
              hide-details
              prepend-icon="mdi-map-marker"
              flat
              required
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </fieldset>

      <fieldset class="">
        <legend>Flugzeiten</legend>

        <v-row>
          <v-col :cols="6">
            <v-checkbox v-model="form.is_batch" :label="$t('label.isBatchEntry')"></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model="form.date"
              :label="$t('label.Date')"
              :error-messages="dateErrors"
              @input="$v.form.date.$touch()"
              prepend-icon="mdi-calendar"
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
            ></v-text-field>

            <!-- <v-menu ref="menuDate" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" v-if="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.date"
                  :label="$t('label.Date')"
                  :error-messages="dateErrors"
                  @input="$v.form.date.$touch()"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date" @change="handleBeforeLatest"></v-date-picker>
            </v-menu> -->
          </v-col>

          <v-col :cols="12" :sm="6" v-if="form.is_batch">
            <v-text-field
              v-model="form.flight_time"
              :label="$t('label.flighttime')"
              placeholder="HH:mm"
              :error-messages="flightTimeErrors"
              @blur="$v.form.flight_time.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="!form.is_batch">
          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model="form.to_utc"
              :label="$t('label.takeOff')"
              :placeholder="$t('placeholder.takeOff')"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              prepend-icon="mdi-airplane-takeoff"
              :error-messages="takeOffErrors"
              type="time"
              @blur="$v.form.to_utc.$touch()"
              @input="$v.form.to_utc.$touch()"
            ></v-text-field>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model="form.ldg_utc"
              :label="$t('label.ldg')"
              :placeholder="$t('placeholder.ldg')"
              pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
              prepend-icon="mdi-airplane-landing"
              type="time"
              :error-messages="ldg_utcErrors"
              @blur="$v.form.ldg_utc.$touch()"
              @input="$v.form.ldg_utc.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="false">
          <v-col :cols="12" :sm="6">
            <v-menu
              ref="menuOffblock"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.off_block_utc"
                  :label="$t('label.offBlock')"
                  :placeholder="$t('placeholder.offBlock')"
                  prepend-icon="mdi-clock-start"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="offBlockErrors"
                  @blur="$v.form.off_block_utc.$touch()"
                  @input="$v.form.off_block_utc.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker v-model="form.off_block_utc" format="24hr" flat full-width></v-time-picker>
            </v-menu>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-menu
              ref="menuon_block_utc"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.on_block_utc"
                  :label="$t('label.onBlock')"
                  :placeholder="$t('placeholder.onBlock')"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="onBlockErrors"
                  @blur="$v.form.on_block_utc.$touch()"
                  @input="$v.form.on_block_utc.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker v-model="form.on_block_utc" format="24hr" flat full-width></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </fieldset>

      <fieldset class="">
        <legend>Zähler</legend>
        <v-row>
          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model.number="form.cnt_ldg"
              :label="$t('label.countLandings')"
              :placeholder="$t('placeholder.countLandings')"
              :error-messages="cnt_ldgErrors"
              @blur="$v.form.cnt_ldg.$touch()"
              @input="$v.form.cnt_ldg.$touch()"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model="form.hobbs_eng1_start"
              :label="$t('label.hoobsEng1Start')"
              placeholder="HH:mm"
              :error-messages="hobbsEng1StartErrors"
              @blur="$v.form.hobbs_eng1_start.$touch()"
            ></v-text-field>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model="form.hobbs_eng1_end"
              :label="$t('label.hoobsEng1End')"
              placeholder="HH:mm"
              :error-messages="hobbsEng1EndErrors"
              @blur="$v.form.hobbs_eng1_end.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model.number="form.cnt_cycle_eng1_start"
              :label="$t('label.countCycleEng1Start')"
              :error-messages="cntCycleEng1StartErrors"
              @blur="$v.form.cnt_cycle_eng1_start.$touch()"
              @input="$v.form.cnt_cycle_eng1_start.$touch()"
            ></v-text-field>
          </v-col>

          <v-col :cols="12" :sm="6">
            <v-text-field
              v-model.number="form.cnt_cycle_eng1_end"
              :label="$t('label.countCycleEng1End')"
              :error-messages="cntCycleEng1EndErrors"
              @blur="$v.form.cnt_cycle_eng1_end.$touch()"
              @input="$v.form.cnt_cycle_eng1_end.$touch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </fieldset>

      <v-alert type="error" text class="mt-5" v-if="error.isVisible">
        {{ error.text }}
      </v-alert>

      <v-row class="mt-3">
        <v-col :cols="12" class="flex justify-end">
          <v-btn color="primary" text :ripple="false" class="mr-2" @click.prevent="cancel">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" type="submit" :ripple="false" depressed :loading="isLoading">{{ $t('btn.save') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog v-model="dialog.isOpen" max-width="30rem">
      <v-card>
        <v-card-title>{{ $t('headline.editType') }}</v-card-title>
        <v-card-text>{{ $t('text.isAddendumEntry') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="dialog.isOpen = false">
            {{ $t('btn.no') }}
          </v-btn>
          <v-btn
            color="success"
            depressed
            @click="
              dialog.isOpen = false;
              form.type = types[2].value;
            "
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf, integer, maxValue, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import { addHours, addMinutes, isBefore } from 'date-fns';
import { getIcaos } from '@/services/icao';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as FLIGHTLOGS_MODULE } from '@/store/modules/customerAircraftFlightlogs';
import { MODULE_NAME as SNACKBAR_MODULE } from '@/store/modules/snackbar';
import { MODULE_NAME as USERS_MODULE } from '@/store/modules/user';

const regexp = /([0-9])*[:]([0-5][0-9])/gm;
const hourMinuteFormat = value => {
  let result = true;

  if (value) {
    result = value.match(regexp) ? true : false;
  }

  return result;
};

export default {
  name: 'FormFlightlog',

  mixins: [validationMixin],

  validations: {
    form: {
      customer_aircraft: { required },
      date: { required },
      registration: { required },
      pilot: { required },
      location_ldg: { required },
      location_to: { required },
      flight_time: {
        hourMinuteFormat,
        required: requiredIf(function() {
          return this.form.is_batch;
        })
      },
      off_block_utc: {},
      to_utc: {
        required: requiredIf(function() {
          return !this.form.is_batch;
        })
      },
      ldg_utc: {
        required: requiredIf(function() {
          return !this.form.is_batch;
        })
      },
      on_block_utc: {},
      cnt_guests: {
        integer,
        maxValue: maxValue(3)
      },
      cnt_ldg: {
        required,
        integer,
        minValue: minValue(0),
        maxValue: maxValue(100)
      },
      hobbs_eng1_start: { hourMinuteFormat },
      hobbs_eng1_end: { hourMinuteFormat },
      cnt_cycle_eng1_start: { integer },
      cnt_cycle_eng1_end: { integer },
      notes: {
        required: requiredIf(function() {
          return this.form.type !== this.types[0].value;
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: `${USERS_MODULE}/currentUser`,
      customerAircrafts: `${CUSTOMER_AIRCRAFTS_MODULE}/all`,
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`,
      isLoading: `${FLIGHTLOGS_MODULE}/isLoading`,
      latestFlightlog: `${FLIGHTLOGS_MODULE}/latest`,
      users: `${USERS_MODULE}/all`
    }),

    aircraftErrors() {
      const errors = [];
      if (!this.$v.form.customer_aircraft.$dirty) {
        return errors;
      }
      if (!this.$v.form.customer_aircraft.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    cnt_guestsErrors() {
      const errors = [];
      if (!this.$v.form.cnt_guests.$dirty) {
        return errors;
      }
      if (!this.$v.form.cnt_guests.integer) {
        errors.push(this.$t('text.WrongFormat'));
      }

      if (!this.$v.form.cnt_guests.maxValue) {
        errors.push(this.$t('text.wrongValue'));
      }
      return errors;
    },

    cnt_ldgErrors() {
      const errors = [];
      if (!this.$v.form.cnt_ldg.$dirty) {
        return errors;
      }
      if (!this.$v.form.cnt_ldg.integer) {
        errors.push(this.$t('text.WrongFormat'));
      }

      if (!this.$v.form.cnt_ldg.minValue) {
        errors.push(this.$t('text.wrongValue'));
      }

      if (!this.$v.form.cnt_ldg.maxValue) {
        errors.push(this.$t('text.wrongValue'));
      }
      if (!this.$v.form.cnt_ldg.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    cntCycleEng1EndErrors() {
      const errors = [];
      if (!this.$v.form.cnt_cycle_eng1_end.$dirty) {
        return errors;
      }
      if (!this.$v.form.cnt_cycle_eng1_end.integer) {
        errors.push(this.$t('text.WrongFormat'));
      }

      return errors;
    },

    cntCycleEng1StartErrors() {
      const errors = [];
      if (!this.$v.form.cnt_cycle_eng1_start.$dirty) {
        return errors;
      }
      if (!this.$v.form.cnt_cycle_eng1_start.integer) {
        errors.push(this.$t('text.WrongFormat'));
      }

      return errors;
    },

    dateErrors() {
      const errors = [];
      if (!this.$v.form.date.$dirty) {
        return errors;
      }
      if (!this.$v.form.date.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    hobbsEng1EndErrors() {
      const errors = [];
      if (!this.$v.form.hobbs_eng1_end.$dirty) {
        return errors;
      }

      if (!this.$v.form.hobbs_eng1_end.hourMinuteFormat) {
        errors.push(this.$t('text.WrongFormat'));
      }

      return errors;
    },

    flightTimeErrors() {
      const errors = [];
      if (!this.$v.form.flight_time.$dirty) {
        return errors;
      }

      if (!this.$v.form.flight_time.hourMinuteFormat) {
        errors.push(this.$t('text.WrongFormat'));
      }

      if (!this.$v.form.flight_time.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }

      return errors;
    },

    hobbsEng1StartErrors() {
      const errors = [];
      if (!this.$v.form.hobbs_eng1_start.$dirty) {
        return errors;
      }

      if (!this.$v.form.hobbs_eng1_start.hourMinuteFormat) {
        errors.push(this.$t('text.WrongFormat'));
      }

      return errors;
    },

    location_ldgErrors() {
      const errors = [];
      if (!this.$v.form.location_ldg.$dirty) {
        return errors;
      }
      if (!this.$v.form.location_ldg.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    location_toErrors() {
      const errors = [];
      if (!this.$v.form.location_to.$dirty) {
        return errors;
      }
      if (!this.$v.form.location_to.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    pilotErrors() {
      const errors = [];
      if (!this.$v.form.pilot.$dirty) {
        return errors;
      }
      if (!this.$v.form.pilot.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    registrationErrors() {
      const errors = [];
      if (!this.$v.form.registration.$dirty) {
        return errors;
      }
      if (!this.$v.form.registration.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    offBlockErrors() {
      const errors = [];
      if (!this.$v.form.off_block_utc.$dirty) {
        return errors;
      }
      // if (!this.$v.form.off_block_utc.required) {
      //   errors.push(this.$t('text.EnterRequiredFields'));
      // }
      return errors;
    },

    onBlockErrors() {
      const errors = [];
      if (!this.$v.form.on_block_utc.$dirty) {
        return errors;
      }
      // if (!this.$v.form.on_block_utc.required) {
      //   errors.push(this.$t('text.EnterRequiredFields'));
      // }
      return errors;
    },

    takeOffErrors() {
      const errors = [];
      if (!this.$v.form.to_utc.$dirty) {
        return errors;
      }
      if (!this.$v.form.to_utc.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    ldg_utcErrors() {
      const errors = [];
      if (!this.$v.form.ldg_utc.$dirty) {
        return errors;
      }
      if (!this.$v.form.ldg_utc.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    notesErrors() {
      const errors = [];
      if (!this.$v.form.notes.$dirty) {
        return errors;
      }
      if (!this.$v.form.notes.required) {
        errors.push(this.$t('text.EnterRequiredFields'));
      }
      return errors;
    },

    toUTC() {
      let result = null;
      const splitted = this.form.to_utc?.split(':') || [];
      if (this.form.date && this.form.to_utc) {
        result = new Date(`${this.form.date}`);
        result.setUTCHours(splitted[0]);
        result.setUTCMinutes(splitted[1]);
      } else if (this.form.date && this.form.is_batch) {
        result = new Date(`${this.form.date}`);
        result.setUTCHours(0);
        result.setUTCMinutes(0);
      } else if (!this.form.date && this.form.to_utc) {
        result = new Date();
        result.setUTCHours(splitted[0]);
        result.setUTCMinutes(splitted[1]);
      }
      return result;
    },

    ldgUTC() {
      let result = null;
      const splitted = this.form.ldg_utc?.split(':') || [];
      if (this.form.date && this.form.ldg_utc) {
        result = new Date(`${this.form.date}`);
        result.setUTCHours(splitted[0]);
        result.setUTCMinutes(splitted[1]);
      } else if (this.form.date && this.form.is_batch && this.form.flight_time) {
        const flightTimeSplitted = this.form.flight_time?.split(':') || [];
        result = addHours(this.toUTC, flightTimeSplitted[0]);
        result = addMinutes(result, flightTimeSplitted[1]);
      } else if (!this.form.date && this.form.ldg_utc) {
        result = new Date();
        result.setUTCHours(splitted[0]);
        result.setUTCMinutes(splitted[1]);
      }
      return result;
    },

    offBlockUTC() {
      let result = null;
      if (this.form.date && this.form.off_block_utc) {
        result = new Date(`${this.form.date} ${this.form.off_block_utc}`);
      }
      return result;
    },

    onBlockUTC() {
      let result = null;
      if (this.form.date && this.form.on_block_utc) {
        result = new Date(`${this.form.date} ${this.form.on_block_utc}`);
      }
      return result;
    }
  },

  data() {
    return {
      airports: [],
      dialog: {
        isOpen: false
      },
      error: {
        isVisible: false,
        text: ''
      },
      form: {
        customer_aircraft: null,
        registration: null,
        pilot: null,
        copilot: null,
        cnt_guests: null,
        location_ldg: null,
        location_to: null,
        date: null,
        to_utc: null,
        off_block_utc: null,
        ldg_utc: null,
        on_block_utc: null,
        cnt_ldg: 1,
        hobbs_eng1_start: '',
        hobbs_eng1_end: '',
        cnt_cycle_eng1_start: null,
        cnt_cycle_eng1_end: null,
        is_batch: false,
        type: 'origin',
        notes: null
      },
      isLoadingAirports: false,
      searchAirportTo: null,
      searchAirportLdg: null,
      types: [
        {
          text: 'Origin',
          value: 'origin'
        },
        {
          text: 'Correction',
          value: 'correction'
        },
        {
          text: 'Addendum',
          value: 'addendum'
        }
      ]
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    cancel() {
      // this.$refs['form'].reset();
      this.$refs['form'].resetValidation();
      this.$emit('cancel');
    },

    getAircraftText(item) {
      const variant = get(item, 'aircraft.easa_types.variant');
      let result = `${item.serial_number}`;
      if (variant) {
        result = `${item.serial_number}  (${variant})`;
      }
      return result;
    },

    getIcaoText(item) {
      let result = item.apt_name;

      if (item.icao) {
        result = `${result} (${item.icao})`;
      }
      if (item.apt_country_man) {
        result = `${result} - ${item.apt_country_man}`;
      }
      return result;
    },

    getUserText(item) {
      const result = `${item.last_name}, ${item.first_name}`;

      return result;
    },

    handleBeforeLatest() {
      const isBeforeLatestLog = this.isBeforeLatestLog();
      if (isBeforeLatestLog && this.form.type !== this.types[2].value) {
        this.dialog.isOpen = true;
      }
    },

    init() {
      this.$store.dispatch(`${USERS_MODULE}/GET_USERS`);
      this.form.pilot = this.getUserText(this.currentUser);
      if (this.customerAircraft) {
        this.form.customer_aircraft = this.customerAircraft.id;
        this.form.registration = this.customerAircraft.registration;
      }
    },

    isBeforeLatestLog() {
      let result = true;
      const toUTC = this.latestFlightlog?.to_utc ? new Date(`${this.latestFlightlog.to_utc}`) : new Date();
      const ldgUTC = this.latestFlightlog?.ldg_utc ? new Date(`${this.latestFlightlog.to_utc}`) : new Date();

      if (this.toUTC) {
        result = isBefore(this.toUTC, toUTC);
      }

      if (this.ldgUTC) {
        result = isBefore(this.ldgUTC, ldgUTC);
      }

      return result;
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        // Show validation errors
      } else {
        // do your submit logic here
        this.doSave();
      }
    },

    async doSave() {
      const ACTION = `${FLIGHTLOGS_MODULE}/CREATE`;
      const PAYLOAD = Object.assign({}, this.form);
      PAYLOAD.off_block_utc = this.offBlockUTC;
      PAYLOAD.to_utc = this.toUTC;
      PAYLOAD.ldg_utc = this.ldgUTC;
      PAYLOAD.on_block_utc = this.onBlockUTC;
      PAYLOAD.status = 'published';

      const result = await this.$store.dispatch(ACTION, PAYLOAD);
      const { id } = result;

      console.log('doSave', { result }, id);

      // Success
      if (id) {
        this.error.isVisible = false;
        this.error.text = '';

        this.$refs['form'].reset();
        this.$refs['form'].resetValidation();
        this.$emit('save', result);

        this.$store.dispatch(`${SNACKBAR_MODULE}/OPEN`, {
          text: this.$t('text.flightlogEntrySavedSuccessfully'),
          timeout: 15000,
          color: 'black'
        });

        this.$store.dispatch(`${FLIGHTLOGS_MODULE}/GET_FLIGHTLOGS`, {
          filter: {
            customer_aircraft: {
              _eq: this.customerAircraft.id
            }
          }
        });
      } else {
        this.error.isVisible = true;
        this.error.text = result;
      }
    },

    onSelectAircraft(value) {
      const aircraft = this.customerAircrafts.find(item => item.id === value);
      if (aircraft) {
        this.form.registration = aircraft.registration;
      }
    },

    async requestAirports(value) {
      try {
        this.isLoadingAirports = true;
        const { data = [] } = await getIcaos(-1, value);

        if (data) {
          this.airports = data.slice();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoadingAirports = false;
      }
    }
  },

  watch: {
    'currentUser.id'() {
      this.form.pilot = this.getUserText(this.currentUser);
    },
    'customerAircraft.id'() {
      this.form.customer_aircraft = this.customerAircraft.id;
      this.form.registration = this.customerAircraft.registration;
    },
    searchAirportLdg(newValue) {
      if (newValue && newValue.length > 2) {
        this.requestAirports(newValue);
      }
    },
    searchAirportTo(newValue) {
      if (newValue && newValue.length > 2) {
        this.requestAirports(newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
fieldset {
  border-color: var(--c-light-gray);
  border-width: 1px;
  padding: 0 1rem 1.5rem;

  &:first-of-type {
    background-color: var(--c-light-gray);
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }
}
</style>
