import { CHANGE_PASSWORD } from '@/router/route-types';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import ChangePassword from '@/views/ChangePassword.vue';

export default {
  path: '/change-password',
  component: DefaultLayout,
  meta: {
    auth: false
  },
  children: [
    {
      path: '',
      name: CHANGE_PASSWORD,
      component: ChangePassword,
      meta: {
        auth: false
      }
    }
  ]
};
