<template>
  <v-menu bottom :offset-y="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" :ripple="false" class="d-flex align-center w-100 text-capitalize">
        <div class="avatar avatar--circle avatar--initials m-r-half">
          <span class="avatar__initials">{{ userInitials }}</span>
        </div>

        <div class="ml-2 d-flex flex-column">
          <span class="font--small">{{ user.first_name }} {{ user.last_name }}</span>
          <span class="font--small color-gray">({{ user.role.name }})</span>
        </div>

        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <ListBarMenu />
  </v-menu>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';
import ListBarMenu from '@/components/List/ListBarMenu';

export default {
  name: 'UserProfile',

  components: {
    ListBarMenu
  },

  computed: {
    ...mapGetters({
      company: `${USER_MODULE}/company`,
      user: `${USER_MODULE}/currentUser`
    }),

    title() {
      return process.env.VUE_APP_TITLE;
    },

    userInitials() {
      let result = '';
      const first_name = get(this.user, 'first_name');
      const last_name = get(this.user, 'last_name');

      if (first_name && last_name) {
        result = `${first_name.charAt(0)}${last_name.charAt(0)}`;
      }
      return result;
    }
  }
};
</script>

<style lang="scss" scoped></style>
