import * as Translations from '@/services/translations';
import { transformTranslations } from '@/shared/transformers';
import { de } from '@/i18n/translations/de.local.js';
import i18n from '@/i18n/index';

export default {
  namespaced: true,

  state: {
    all: []
  },

  getters: {
    translation: state => state.all
  },

  mutations: {
    SET_TRANSLATION(state, payload) {
      // Set default translation from local file
      if (!payload) {
        i18n.locale = 'de';
        const message = transformTranslations(de);
        i18n.setLocaleMessage('de', message);
        state.all.push({
          id: 'de',
          messages: message
        });
        return;
      }

      const { locale, message } = payload;
      document.querySelector('html').setAttribute('lang', locale);
      i18n.locale = locale;
      i18n.setLocaleMessage(locale, message);

      const obj = {
        id: locale,
        messages: message
      };

      const index = state.all.findIndex(item => item.id === locale);

      if (index !== -1) {
        state.all.splice(index, 1, obj);
      } else {
        state.all.push(obj);
      }
    }
  },

  actions: {
    GET_TRANSLATION({ commit }, payload) {
      return Translations.getTranslation(payload)
        .then(response => {
          const message = transformTranslations(response.data);
          commit('SET_TRANSLATION', { locale: payload, message });
        })
        .catch(err => err);
    }
  }
};

export const MODULE_NAME = 'translations';
