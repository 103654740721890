import { LOGOUT } from '@/router/route-types';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
// import HalfHalfLayout from '@/layouts/HalfHalfLayout.vue';
import Logout from '@/views/Logout.vue';

export default {
  path: '/logout',
  component: DefaultLayout,
  meta: {
    auth: false
  },
  children: [
    {
      path: '',
      name: LOGOUT,
      component: Logout,
      meta: {
        auth: false
      }
    }
  ]
};
