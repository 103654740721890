// Layouts
import DefaultLayout from '@/layouts/DefaultLayout.vue';

// Views
import NotFound from '@/views/404.vue';

// Routes
import BoardbookRoutes from '@/router/routes/boardbook.route';
import ChangePasswordRoutes from '@/router/routes/change-password.route';
import DashboardRoutes from '@/router/routes/dashboard.route';
import DocumentsRoutes from '@/router/routes/documents.route';
import LifecycleRoutes from '@/router/routes/lifecycle.route';
import ListsRoutes from '@/router/routes/lists.route';
import LoginRoutes from '@/router/routes/login.route';
import LogoutRoutes from '@/router/routes/logout.route';
import ManualsRoutes from '@/router/routes/manuals.route';
import OperatinghoursRoutes from '@/router/routes/operatinghours.route';
import ReportRoutes from '@/router/routes/report.route';
import ResetPasswordRoutes from '@/router/routes/reset-password.route';
import SettingsRoutes from '@/router/routes/settings.route';

// import UsersRoutes from '@/router/routes/users.route';

const routes = [
  BoardbookRoutes,

  ChangePasswordRoutes,

  DashboardRoutes,

  DocumentsRoutes,

  LifecycleRoutes,

  ListsRoutes,

  LoginRoutes,

  LogoutRoutes,

  ManualsRoutes,

  OperatinghoursRoutes,

  ReportRoutes,

  ResetPasswordRoutes,

  SettingsRoutes,

  // UsersRoutes,

  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '*',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: NotFound,
        meta: {
          requiresAuth: false
        }
      }
    ]
  }
];

export default routes;
