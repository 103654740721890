export default {
  namespaced: true,

  state: {
    isCollapsed: false,
    isMini: true,
    mobileBreakpoint: 960
  },

  getters: {
    isCollapsed: state => state.isCollapsed,
    isMini: state => state.isMini,
    mobileBreakpoint: state => state.mobileBreakpoint
  },

  mutations: {
    COLLAPSE(state, payload) {
      state.isCollapsed = payload;
    },

    TOGGLE_MINI(state) {
      state.isMini = !state.isMini;
    },

    TOGGLE_COLLAPSE(state) {
      state.isCollapsed = !state.isCollapsed;
    }
  }
};

export const MODULE_NAME = 'menu';
