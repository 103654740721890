<template>
  <div class="view">
    <div class="view__inner">
      <div class="">
        <h1>LAkte</h1>
        <!-- <SVGLogo class="logo" /> -->
      </div>

      <div class="mt-1">
        <FormChangePasswordAfterReset v-if="token" :token="token" />

        <v-alert top :type="alert.type" text v-else>
          <span v-html="alert.message"></span>

          <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="alert.isVisible = false">
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-alert>
      </div>

      <Separator class="mt-6" :show-line="true" :text="$t('or')"></Separator>

      <p class="text-center mt-2">
        <v-btn :to="{ name: LOGIN }" depressed color="primary">
          {{ $t('Login') }}
        </v-btn>
      </p>
    </div>
  </div>
</template>

<script>
import { LOGIN } from '@/router/route-types';
import { get } from 'lodash';
import Separator from '@/components/Misc/Separator';
// import SVGLogo from '@/assets/img/logo-webplus.svg';
import FormChangePasswordAfterReset from '@/components/Form/Auth/FormChangePasswordAfterReset';

export default {
  name: 'ChangePassword',

  components: {
    FormChangePasswordAfterReset,
    Separator
    // SVGLogo
  },

  computed: {
    token() {
      return get(this.$route.query, 'token', false);
    },

    LOGIN() {
      return LOGIN;
    }
  },

  data() {
    return {
      alert: {
        isVisible: false,
        message: 'text.NoToken',
        type: 'error'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.view {
  flex: 1;

  &__inner {
    background-color: #ffffff;
    // box-shadow: 0 0 10px rgba(17, 17, 17, 0.1);

    @media screen and (min-width: $screen-md) {
      max-width: 30rem;
    }
  }
}
</style>
