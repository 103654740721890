<template>
  <div class="app-container" v-resize="onResize">
    <!-- Navigation Drawer -->
    <AppAside />

    <!-- Header -->
    <AppBar />

    <v-main>
      <!-- Main content goes here -->
      <router-view />
    </v-main>

    <!-- Shared components -->
    <Snackbar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import * as Browser from '@/services/browser';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import { MODULE_NAME as COUNTRIES_MODULE } from '@/store/modules/countries';
import { MODULE_NAME as TAGS_MODULE } from '@/store/modules/tags';
import { MODULE_NAME as TRANSLATIONS_MODULE } from '@/store/modules/translations';
import { MODULE_NAME as USER_MODULE } from '@/store/modules/user';

import AppAside from '@/components/App/AppAside';
import AppBar from '@/components/App/AppBar';
import Snackbar from '@/components/Global/Snackbar';

export default {
  name: 'AppLayout',

  components: {
    AppAside,
    AppBar,
    Snackbar
  },

  computed: {
    ...mapGetters({
      company: `${USER_MODULE}/company`,
      user: `${USER_MODULE}/currentUser`
    }),

    tags() {
      return this.$store.getters[`${TAGS_MODULE}/allTranslated`](this.userLangCode);
    },

    userLangCode() {
      return get(this.user, 'language', 'de-DE');
    }
  },

  data() {
    return {
      refreshTokenInterval: null
    };
  },

  created() {
    this.init();
  },

  methods: {
    handleCheckGdpr() {
      if (this.user.accept_privacy_policy) {
        return true;
      }

      // this.$store.commit(`${DIALOG_MODULE}/OPEN`, {
      //   title: this.$t('Privacy policy'),
      //   closeOnClick: false,
      //   showClose: false,
      //   width: '50%',
      //   data: {
      //     data: this.$t('textConfirmPrivacyPolicy')
      //   },
      //   loadComponent: () => import(/* webpackChunkName: "formprivacypolicy" */ '@/components/Form/FormPrivacyPolicy.vue')
      // });
    },

    handleLanguage() {
      const language = Browser.getLanguage();
      let lang = language.toLowerCase();

      if (this.user.language) {
        lang = this.user.language.slice(0, 2);
      }

      // Set default translation from local file
      this.$store.commit(`${TRANSLATIONS_MODULE}/SET_TRANSLATION`);

      const translations = find(this.translations, { id: lang });
      if (translations) {
        this.$store.commit(`${TRANSLATIONS_MODULE}/SET_TRANSLATION`, {
          locale: lang,
          message: translations.messages
        });
      } else {
        this.$store.dispatch(`${TRANSLATIONS_MODULE}/GET_TRANSLATION`, lang);
      }
    },

    async init() {
      // On page refresh handle token and if the token is due to expire get a new one
      // if (!this.token) {
      //   await this.handleToken();
      // } else if (this.auth.expires) {
      //   this.refreshTokenInterval = setInterval(() => {
      //     this.handleToken();
      //   }, this.auth.expires - 10000);
      // }
      // this.requestInitialData();
      setTimeout(() => {
        this.requestInitialData();
      }, 1000);

      // const body = document.querySelector('body');
      // body.style.overflow = 'hidden';

      this.handleViewportHeight();
    },

    handleViewportHeight() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    onResize() {
      this.handleViewportHeight();
    },

    async requestInitialData() {
      // First get current user
      await this.$store.dispatch(`${USER_MODULE}/GET_CURRENT_USER`);
      // Then handle DSGVO and language
      // this.handleCheckGdpr();
      this.handleLanguage();

      await this.$store.dispatch(`${COUNTRIES_MODULE}/GET_COUNTRIES`);
      await this.$store.dispatch(`${CUSTOMER_AIRCRAFTS_MODULE}/GET_AIRCRAFTS`);
      await this.$store.dispatch(`${TAGS_MODULE}/GET_TAGS`);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  background-color: var(--c-page-bg) !important;
  height: 100%;
}

.v-main {
  min-height: 100%;
}
</style>
