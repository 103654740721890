import Vue from 'vue';

// APP
import App from '@/App.vue';

// ROUTER
import router from '@/router/index';

// STORE
import store from '@/store/index';

// PWA
// import '@/registerServiceWorker';

// PLUGINS
import vuetify from '@/plugins/vuetify';

// STYLES
import '@/assets/scss/styles.scss';

// i18n
import i18n from '@/i18n';

// EVENTBUS
// import Eventbus from '@/events/index';

// FORM VALIDATION
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Storage
import Vlf from 'vlf';
import localforage from 'localforage';

// USE PLUGINS
Vue.use(Vlf, localforage);
// Vue.prototype.$eventbus = Eventbus;
Vue.prototype.$store = store;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');
